import React from 'react';
import '../Tools/Tools.scss';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import toolsTopBack from '../img/tools/green-header-inspired.jpg';
import TopNav from '../TopNav/TopNav'
import Footer from '../Footer/Footer'
import downloadIcon from '../img/download-icon-white.png';

function GetInspired() {
  return (
    <div className="get-inspired activity-page tools-subpage">
      <TopNav currentTopNav="tools" />
      <div className="page-top" style={{backgroundImage: `url(${toolsTopBack})`}}>
        <Container className="tools-top tools-subpage-top">
          <Row>
            <Col className="text-left relative" lg={{ span: 4, offset: 1 }}>
              <div className="text-5 tools-subpage-title">Team Activity</div>
              <div className="text-1 tools-subpage-subtitle">Get Inspired</div>
            </Col>
            <Col className="text-left relative" lg={{ span: 7 }}>
              <img className="tools-subpage-hero get-inspired-phone" src={require('../img/tools/get-inspired-phone@2x.png')} alt="Get Inspired"></img>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="normal-section tools-subpage-content">
        <Container>
          <Row className="margin-bottom-big">
            <Col md={{ span: 6, offset: 1 }}  xs={{ span: 10, offset: 1 }}>
              <div className="text-2">Inspiration is the<br /> springboard for creativity</div>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 6, offset: 1 }}>
              <div className="text-5-1 regular tools-subpage-copy-container">
                <div className="tools-subpage-header">Step 1</div>
                Invite relevant team members and stakeholders to attend a one- to two-hour meeting. Let them know that the goal of your meeting is to get inspired by what other products have done to cater to the needs of the next wave of internet users.
              </div>

              <div className="text-5-1 tools-subpage-copy-container">
                <div className="tools-subpage-header">Step 2</div>
                During the meeting, launch the <a id="get-inspired-link" href="/tools/inspiration-tool">Inspiration Tool</a> on a presentation screen or large TV that all attendees can view together. Explore, discuss, and reflect on the product examples or provocations as a group.
              </div>

              <div className="text-5-1 tools-subpage-copy-container">
                <div className="tools-subpage-header">Step 3</div>
                Select a few questions to discuss together:
                <ul className="tools-subpage-list">
                  <li>Do any of these products or provocations feel relevant to our work?</li>
                  <li>Which example was most inspiring?</li>
                  <li>Are there other examples we’ve seen out in the world that inspire us?</li>
                  <li>If we could wave a magic wand and implement one of these examples into our product tomorrow, which would we pick?</li>
                </ul>
              </div>

            </Col>
            <Col md={{ span: 3, offset: 1 }}  xs={{ span: 10, offset: 1 }}>
              <div className="">
                <div className="tools-rightside-header text-5">Goal</div>
                Get inspired by products and UI provocations built for the next wave of internet users
                <div className="tools-rightside-header">Time</div>
                1-2 hours
                <div className="tools-rightside-header">Attendees</div>
                Key team members and stakeholders
                <div className="tools-rightside-header">Materials</div>

                <a href="/tools/inspiration-tool" className="download-tool-button anchor-tag flex-row">
                  <div className="download-icon flex-column" style={{backgroundImage: `url(${downloadIcon})`}}></div>
                  <span className="download-button-text  flex-column">Launch the tool</span>
                </a>

               </div>
            </Col>            
          </Row>
        </Container>
      </div>

      <Footer currentPage="inspired" />

    </div>
  );
}

export default GetInspired;
