import React, { useState } from 'react';
import './Footer.scss';
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import emailjs from 'emailjs-com';


function Contact(props) {

  const [show, setShow] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [fullName, setFullName] = useState("");
  const [organization, setOrganization] = useState("");
  const [message, setMessage] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  // const [emailValid, setEmailValid] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const emailIsValid = () => setEmailValid(true);
  // const emailIsNotValid = () => setEmailValid(false);

  // const handleEmail = (evt) => {
  //   setEmailAddress(evt.target.value);
  //   // test if valid email address
  //   if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(emailAddress)) { 
  //     emailIsValid();
  //   } else {
  //     emailIsNotValid();
  //     // return;
  //   }    
  // }

  // const getButtonText = () => {
  //   let buttenText = "Download the Cards"
  //   if (props.text !== undefined) {
  //     buttenText = props.text;
  //   }
  //   return buttenText;
  // }


  // const errorMessageClass = () => {
  //   if (!emailValid && formSubmitted) {
  //     return "error-message download-button-error show"
  //   } else {
  //     return "error-message download-button-error"
  //   }
  // }

  // const submitForm = (e) => {
  //   e.preventDefault();
  //   // if (emailValid) {
  //   //   handleClose();
  //   // } else {
  //   //   console.log('dont link link to pdf');
  //   // }
  //   handleFormSubmitted();
  //   console.log(formSubmitted);
  // }


  const submitForm = (e) => {
    e.preventDefault();
    
    if (emailAddress === "") {
      setEmailError(true);
      return;
    } else {
      setEmailError(false);
    }
    
    emailjs.send('eklein@ideo.com', 'contact_us', {
      fullName: fullName,
      email: emailAddress,
      organization: organization,
      message: message
    }, 'user_8e6EAauzjBMDFmHrfquT7')
    .then((result) => {
        console.log(result.text);
        setFormSubmitted(true);
    }, (error) => {
        console.log(error.text);
    });
  }


  const updateFullName = (evt) => {
    setFullName(evt.target.value)
  }

  const updateEmail = (evt) => {
    setEmailAddress(evt.target.value);
  }

  const updateOrganization = (evt) => {
    setOrganization(evt.target.value);
  }

  const updateMessage = (evt) => {
    setMessage(evt.target.value);
  }

  const getEmailErrorClass = () => {
    if (emailError) {
      return "contact-email-error show"
    } else {
      return "contact-email-error"
    }
  }

  const getSubmitButtonClass = () => {
    if (formSubmitted) {
      return "button1 sprint-form-submit contact-form hide"
    } else {
      return "button1 sprint-form-submit contact-form"
    }
  }

  const getThankYouClass = () => {
    if (formSubmitted) {
      return "contact-thank-you show"
    } else {
      return "contact-thank-you"
    }
  }

  return (
    <div>
      <div className="contact-link footer-link" onClick={handleShow}>
        Contact
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body className="relative lmm-modal-footer">

            <img onClick={handleClose} className="close-modal-button" src={require('../img/icons/close-white.png')} alt="Close"></img>
            <div className="text-1 margin-bottom-me margin-top-me">Send us a note</div>

            <Form onSubmit={(e) => {submitForm(e)}}>
            <Container className="relative digital-sprint-form-container">
              <Row className="margin-bottom-me">
                <Col className="text-left" md={{ span: 5, offset: 1 }}>
                  <div className="text-5 bold-text text-title-margin-bottom-small">Full Name</div>
                  <Form.Control value={fullName} onChange={evt => updateFullName(evt)} className="margin-bottom-me-small" type="text" placeholder="Rita Brown" />
                  <div className="text-5 bold-text text-title-margin-bottom-small">
                    Email Address
                    <div className={getEmailErrorClass()}>Please add your email address</div>
                  </div>
                  <Form.Control value={emailAddress} onChange={evt => updateEmail(evt)} className="margin-bottom-me-small" type="email" placeholder="rita@newcorp.com"></Form.Control>
                  

                  <div className="text-5 bold-text text-title-margin-bottom-small">Organization</div>
                  <Form.Control value={organization} onChange={evt => updateOrganization(evt)} className="margin-bottom-me-small" type="text" placeholder="Newcorp" />

                  <div className="privacy-text text-6">Please read our <a href="https://www.ideo.com/privacy" rel="noopener noreferrer" target="_blank">Privacy Policy</a> and <a href="https://www.ideo.com/privacy#privacy-rights">CA User Rights</a> to see how we use your personal information.</div>
                </Col>
                <Col className="text-left" md={{ span: 5 }}>
                  <div className="text-5 bold-text text-title-margin-bottom-small">Your message</div>
                  <Form.Control value={message} onChange={evt => updateMessage(evt)} className="margin-bottom-me-small" as="textarea" placeholder="How can we help?" rows="4" />
                  <div className={getThankYouClass()}>Thank you! We'll be in touch.</div>
                  <button type="submit" className={getSubmitButtonClass()}>Submit Response</button>
                </Col>
              </Row>
            </Container>
            </Form>


        </Modal.Body>
      </Modal>
    </div>
  );

}

export default Contact;