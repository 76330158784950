import React, { Component } from 'react';
import './Home.scss';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Footer from '../Footer/Footer'
import TopNav from '../TopNav/TopNav'
import HomeVideo from '../Home/HomeVideo'
import DigitalTools from '../DigitalTools/DigitalTools';
import HomeSquiggles from './HomeSquiggles';
import ParallaxImage from '../Home/ParallaxImage';
import Rellax from 'rellax';


import { motion } from "framer-motion";
import {
  Link
} from "react-router-dom";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false
    }
  }

  componentDidMount() {
    this.setState({loaded: true});
  }

  render() {
    new Rellax(this.rellaxRef)
    new Rellax(this.rellaxRef2)
    return (
      <div className="home">
        <TopNav currentTopNav="home" />
        <div className="page-top">
          <Container>
            <Row className="home-title-row">
              <Col xl={{ span: 6, offset: 3 }} xs={{ span: 10, offset: 1 }}>
                <motion.div 
                  // animate={{ marginBottom: 0 }}
                  className="text-1 margin-bottom-me-small top-title">Designing for<br /> Digital Confidence</motion.div>
                <div className="text-4 regular">The next wave of internet users is coming online across the globe. Are your products ready?</div>
              </Col>
            </Row>
            <Row className="home-top-image-container">
              <Col>

                <HomeSquiggles loaded={this.state.loaded} />

                <motion.img
                  animate={{ opacity: 1 }}
                  transition={{ duration: 3 }} 
                  className="big-image home-big-image" 
                  src={require('../img/home-people-group-png24-new.png')} alt="People"></motion.img>
              </Col>
            </Row>
          </Container>
        </div>

        <HomeVideo />

        <div className="standard-section">
          <Container>
            <Row>
              <Col lg={{ span: 4, offset: 1 }} xs={{ span: 10, offset: 1 }}>
                <div className="text-3 text-title-margin-bottom margin-top-me-none-mobile">Today, more than a million people will come online for the first time in their lives</div>
                <div className="">And tomorrow and the day after, a million more will follow. A million people largely from emerging markets, and increasingly from rural areas. A million people who may speak multiple languages, but struggle to read or type. A million people whose first exposure to the digital world is through their phone.</div>
              </Col>
              <Col lg={{ span: 6, offset: 1 }} className="relative mobile-no-right-padding">
                <img className="big-image" src={require('../img/home-person-1-new.png')} alt="Person"></img>
                
                <div className="home-lady-squiggle-container" data-rellax-speed="2" ref={ref => { this.rellaxRef = ref }}>
                  <img alt="lmm" className="home-lady-squiggle" src={require('../img/home-lady-squiggle.png')} />
                </div>
                  
              </Col>
            </Row>  
          </Container>
        </div>  

        <div className="normal-section home-second-person-row">
          <Container>
            <Row>
              <Col lg={{ span: 6, order: 1 }} xs={{span: 12, order: 2}} className="relative mobile-no-left-padding">
                <img className="big-image guy-looking-at-phone" src={require('../img/home-person-2-new.png')} alt="Person"></img>
              
                <div className="home-guy-squiggle-container" data-rellax-speed="2" ref={ref => { this.rellaxRef2 = ref }}>
                  <img alt="lmm" className="home-guy-squiggle" src={require('../img/question-mark.png')} />
                </div>
              
              </Col>
              <Col lg={{ span: 4, order: 2 }} xs={{span: 10, offset: 1, order: 1}}>
                <div className="text-3 text-title-margin-bottom">The digital world has no owner’s manual</div>
                <div className="">For someone new to it, technology can be exciting… and intimidating. Internet access opens the door to a world of possibility. But before you can enter, there’s a lot you need to know: How to navigate from screen to screen. What’s tappable and what’s not. What all those strange icons mean. How to find search results in a language you can read. How to stay safe. How to even begin.</div>
              </Col>
            </Row>  
          </Container>
        </div>

        <div className="purple-section hill">
          <Container className="purple-back contain-on-mobile relative">
            {/* <img className="hill-squiggle" src={require('../img/hill-squiggle.png')} alt="Uphill"></img>             */}
            <Row className="above-uphill">
              <Col lg={{ span: 4, offset: 1 }} xs={{ span: 10, offset: 1 }}>
                <div className="text-3 text-title-margin-bottom">Together, we can build experiences that work for everyone</div>
                <div className="">Designing for digital confidence means technology that’s accessible to everyone, everywhere, every time. Regardless of gender, geography, language, device type, or education level.</div>
                <Link to="/opportunities" className="button1 explore-opps-button">Explore Opportunities &amp; Obstacles</Link>
              </Col>
            </Row>
            <ParallaxImage />
          </Container>
          <img className="big-image uphill-image" src={require('../img/uphill2@2x.png')} alt="Uphill"></img>
        </div>

        <DigitalTools />
        <Footer hideTopFooterSection="true" />
      </div>
    );
  }
}

export default Home;