import React from "react";
import './Home.scss';
import { useState, useRef, useLayoutEffect } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";

const HomeSquiggles = (props) => {
  const [elementTop, setElementTop] = useState(0);
  // const [bodyHeight, setBodyHeight] = useState(0);
  const ref = useRef(null);
  const { scrollY } = useViewportScroll();
  // scrollY.onChange((props) => console.log('change', props))
  const y = useTransform(scrollY, [elementTop, 1000], [200, -100], {
    clamp: false
  });

  // const homeSquiggleVariants = {
  //   splayed: () => ({
  //     opacity: 1,
  //     top: "-40px",
  //     transition: {
  //       duration: 1
  //       // delayChildren: 0.2
  //     }
  //   }),
  //   initial: () => ({
  //     opacity: 1,
  //     top: "0px",
  //   })
  // };

  useLayoutEffect(() => {
    // setBodyHeight(window.document.body.offsetHeight);
    const element = ref.current;
    setElementTop(element.offsetTop);
  }, [ref]);

  return (
    <motion.div
      ref={ref} 
      style={{ y }}
      className="big-home-parallax"
      animate={{
        opacity: [0, 1],
        top: [0, -220],
      }}
      // animate={props.loaded === true ? "splayed" : "initial"}
      // variants={homeSquiggleVariants}
      >
      <img className="big-image home-top-squiggles" src={require('../img/parallax/home-top-squiggles.png')} alt="Squiggles"></img>
    </motion.div>


  );
};

export default HomeSquiggles;
