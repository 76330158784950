import React from "react";
import { useState, useRef, useLayoutEffect } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import MediaQuery from 'react-responsive'

function BoxCards (props) {
  const [elementTop, setElementTop] = useState(0);
  const [bodyHeight, setBodyHeight] = useState(0);
  const ref = useRef(null);
  const { scrollY } = useViewportScroll();
  // scrollY.onChange((props) => console.log('change', props))
  const y = useTransform(scrollY, [elementTop, bodyHeight], [200, -100], {
    clamp: false
  });

  useLayoutEffect(() => {
    setBodyHeight(window.document.body.offsetHeight);
    const element = ref.current;
    setElementTop(element.offsetTop);
  }, [ref]);

  return (
    <div ref={ref} className="image-container relative">

      <MediaQuery minDeviceWidth={992}>
        <motion.img style={{ y }} className="sprint-box-cards sprint-box-image" src={require('../img/parallax/box-cards@2x.png')} alt="Sprint Box"></motion.img>
      </MediaQuery>
      <MediaQuery maxDeviceWidth={991}>
        {props.page
          ? <img className="sprint-box-cards higher sprint-box-image" src={require('../img/parallax/box-cards@2x.png')} alt="Sprint Box"></img>
          : <motion.img style={{ y }} className="sprint-box-cards sprint-box-image" src={require('../img/parallax/box-cards@2x.png')} alt="Sprint Box"></motion.img>
        }
      </MediaQuery>


      
    </div>
  );
};

export default BoxCards;
