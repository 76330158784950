import React, { useState, useRef } from 'react';
import './AudioClips.scss';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ReactAudioPlayer from 'react-audio-player';
import jasonAudio from '../audio/Jason.m4a';
import ajaitaAudio from '../audio/Ajaita.m4a';
import toshAudio from '../audio/Tosh.m4a';
import rikinAudio from '../audio/Rikin.m4a';
import traceyAudio from '../audio/Tracey.m4a';

function AudioClips() {

  const toshAudioRef = useRef(null);
  const traceyAudioRef = useRef(null);
  const rikinAudioRef = useRef(null);
  const jasonAudioRef = useRef(null);
  const ajaitaAudioRef = useRef(null);

  const onAudioPlay = (who) => {
    if (who === "tosh") {
      traceyAudioRef.current.audioEl.pause();
      rikinAudioRef.current.audioEl.pause();
      jasonAudioRef.current.audioEl.pause();
      ajaitaAudioRef.current.audioEl.pause();
    }
    if (who === "tracey") {
      toshAudioRef.current.audioEl.pause();
      rikinAudioRef.current.audioEl.pause();
      jasonAudioRef.current.audioEl.pause();
      ajaitaAudioRef.current.audioEl.pause();
    } 
    if (who === "rikin") {
      traceyAudioRef.current.audioEl.pause();
      toshAudioRef.current.audioEl.pause();
      jasonAudioRef.current.audioEl.pause();
      ajaitaAudioRef.current.audioEl.pause();
    }
    if (who === "jason") {
      traceyAudioRef.current.audioEl.pause();
      rikinAudioRef.current.audioEl.pause();
      toshAudioRef.current.audioEl.pause();
      ajaitaAudioRef.current.audioEl.pause();
    }
    if (who === "ajaita") {
      traceyAudioRef.current.audioEl.pause();
      rikinAudioRef.current.audioEl.pause();
      jasonAudioRef.current.audioEl.pause();
      toshAudioRef.current.audioEl.pause();
    }

  }  

  return (
    <div className="audio-clips">
      <Container className="audio-clips-title-container">
        <Row>
          <Col className="text-center" lg={{ span: 6, offset: 3 }}>
            <div className="text-2">Designing for digital confidence requires a new way of thinking</div>
          </Col>
        </Row>

        <Row className="audio-row">
          <Col className="text-left audio-text-col" lg={{ span: 4, offset: 2, order: 1 }} xs={{span: 12, order: 2}}>
            <div className="text-6"><strong>Tosh Juma</strong></div>
            <div className="text-6 text-title-margin-bottom">Managing Director, ideo.org Nairobi</div>
            <div className="text-3 regular margin-bottom-me-small">“The switch from designing <i>for</i> to designing <i>with</i> is super powerful.”</div>
            <ReactAudioPlayer
              src={toshAudio}
              autoPlay={false}
              controls={true}
              onPlay={() => {onAudioPlay('tosh')}}
              ref={toshAudioRef}
            />
          </Col>
          <Col lg={{ span: 6, order: 2 }} xs={{span: 12, order: 1}}>
            <img className="audio-human" src={require('../img/tosh.png')} alt="Tosh"></img>
          </Col>
        </Row>

        <Row className="audio-row">
          <Col className="text-right" lg={{ span: 6 }}>
            <img className="audio-human" src={require('../img/tracey.png')} alt="Tracey"></img>
          </Col>
          <Col className="text-left audio-text-col" lg={{ span: 4, offset: 1 }}>
            <div className="text-6"><strong>Tracey Lindsay Chan</strong></div>
            <div className="text-6 text-title-margin-bottom">Senior User Experience Researcher, Google Next Billion Users</div>
            <div className="text-35 regular margin-bottom-me-small">“The first day of people having their very first smartphone is full of a lot of emotions.”</div>
            <ReactAudioPlayer
              src={traceyAudio}
              autoPlay={false}
              controls={true}
              onPlay={() => {onAudioPlay('tracey')}}
              ref={traceyAudioRef}
            />
          </Col>
        </Row>

        <Row className="audio-row">
          <Col className="text-left audio-text-col" lg={{ span: 4, offset: 2, order: 1 }} xs={{span: 12, order: 2}}>
            <div className="text-6"><strong>Rikin Gandhi</strong></div>
            <div className="text-6 text-title-margin-bottom">CEO, Digital Green</div>
            <div className="text-35 regular margin-bottom-me-small">“At present, there’s not all that much content out there that’s relevant and they can identify with.”</div>
            <ReactAudioPlayer
              src={rikinAudio}
              autoPlay={false}
              controls={true}
              onPlay={() => {onAudioPlay('rikin')}}
              ref={rikinAudioRef}
            />
          </Col>
          <Col lg={{ span: 6, order: 2 }} xs={{span: 12, order: 1}}>
            <img className="audio-human" src={require('../img/rikin.png')} alt="Rikin"></img>
          </Col>
        </Row>

        <Row className="audio-row  margin-bottom-big">
          <Col className="text-left" lg={{ span: 4, offset: 2 }}>
            <img className="audio-human" src={require('../img/jason.png')} alt="Jason"></img>
            <div className="text-6"><strong>Jason Fund</strong></div>
            <div className="text-6 text-title-margin-bottom">Design Lead, IDEO</div>
            <div className="text-35 thin-text regular margin-bottom-me-small">“Voice is opening the floodgates to the digital world for hundreds of millions of people.”</div>
            <ReactAudioPlayer
              src={jasonAudio}
              autoPlay={false}
              controls={true}
              onPlay={() => {onAudioPlay('jason')}}
              ref={jasonAudioRef}
            />
          </Col>
          <Col className="text-left" lg={{ span: 4, offset: 1 }}>
            <img className="audio-human margin-top-me-big" src={require('../img/ajaitha.png')} alt="Ajaitha"></img>
            <div className="text-6"><strong>Ajaita Shah</strong></div>
            <div className="text-6 text-title-margin-bottom">CEO, Frontier Markets</div>
            <div className="text-5 thin-text regular margin-bottom-me-small">“Our perspective is high tech with high touch. People need assistance, handholding, and patience.”</div>
            <ReactAudioPlayer
              src={ajaitaAudio}
              autoPlay={false}
              controls={true}
              onPlay={() => {onAudioPlay('ajaita')}}
              ref={ajaitaAudioRef}
            />
          </Col>
        </Row>

      </Container>
    </div>
  );
}

export default AudioClips;
