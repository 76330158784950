import React, { Component } from 'react';
import './About.scss';
import toolsTopBack from '../img/about-header.png';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import TopNav from '../TopNav/TopNav'
import Footer from '../Footer/Footer'

class About extends Component {

  componentDidMount() {
    // console.log(this.props.currentTopNav);
  }

  render() {
    return (
      <div className="about">
        <TopNav />
        <div className="page-top" style={{backgroundImage: `url(${toolsTopBack})`}}>
          <Container className="about-top">
            <Row>
              <Col className="text-left relative" md={{ span: 7, offset: 1 }} xs={{ span: 10, offset: 1 }}>
                <div className="text-1 margin-bottom-me about-">We want to catalyze a global community of product makers</div>
              </Col>
            </Row>
            <Row>
              <Col className="text-left" md={{span: 6, offset: 1}}  xs={{ span: 10, offset: 1 }}>
                <div className="text-35 regular about-subTitle">We’re a network of designers, researchers, developers, and product managers committed to building better digital services for the next wave of internet users</div>
              </Col>
            </Row>
          </Container>
        </div>
        <Container className="normal-section">
          <Row>
            <Col className="text-center relative" md={{ span: 8, offset: 2 }}  xs={{ span: 10, offset: 1 }}>
              <div className="text-2 margin-bottom-me">This site is a product of Last Mile Money, a five-year initiative to connect underserved communities to the digital economy</div>
            </Col>
          </Row>
          <Row>
            <Col className="text-center relative" md={{ span: 12 }} lg={{ span: 8, offset: 2 }}>
              <img className="about-logo" src={require('../img/ideo-logo-dark.png')} alt="Get Inspired"></img>
              <img className="about-logo" src={require('../img/gates-logo-dark.png')} alt="Get Inspired"></img>
              <img className="about-logo" src={require('../img/google-logo-dark.png')} alt="Get Inspired"></img>
            </Col>
          </Row>
        </Container>

        <div className="grey-section">
          <Container className="about-top">
            <Row>
              <Col className="text-left relative" md={{ span: 4, offset: 1 }}  xs={{ span: 10, offset: 1 }}>
                <img className="about-squiggle" src={require('../img/about-squiggle.png')} alt="squiggle"></img>
                <div className="text-2 margin-bottom-me">We’re building upon the research and rigor of our peers</div>
                <div className="margin-bottom-me">Here are a few of the resources we’ve found to be especially inspiring, informative, and impactful to our work.</div>
              </Col>
              <Col className="text-left relative" md={{ span: 2, offset: 2 }}  xs={{ span: 5, offset: 1 }}>
                <div className="text-6 margin-bottom-me-about-page">
                  <a href="https://www.womenandmoney.design/" target="_blank" rel="noopener noreferrer" className="about-link">
                    Women &amp; Money: A Path To Close The Gender Gap
                  </a>
                  <a href="https://d20x8vt12bnfa2.cloudfront.net/reports/Stepping+Into+Digital+Life+-+Digital+Skills+Observatory+Research+Report.pdf" target="_blank" rel="noopener noreferrer" className="about-link">
                    Digital Skills Observatory
                  </a>

                  <a href="https://www.blog.google/technology/next-billion-users/how-insights-user-research-help-us-build-next-billion-users/" target="_blank" rel="noopener noreferrer" className="about-link">
                    How Insights From User Research Help Us Build For The Next Billion
                  </a>
                  <a href="https://www.youtube.com/watch?v=JkQ4ID1nsq4&feature=emb_title" target="_blank" rel="noopener noreferrer" className="about-link">
                    Assistive UI Meets Bharat
                  </a>

                  <a href="http://services.google.com/fh/files/misc/toward_gender_equity_online.pdf" target="_blank" rel="noopener noreferrer" className="about-link">
                    Toward Gender Equity Online
                  </a>
                  <a href="https://youtu.be/UVtzCDTNbMI" target="_blank" rel="noopener noreferrer" className="about-link">
                    Designing For Low-Literate Users
                  </a>

                  <a href="https://www.designkit.org/" target="_blank" rel="noopener noreferrer" className="about-link">
                    Design Kit: The Human-Centered Design Toolkit
                  </a>
                  <a href="https://drive.google.com/file/d/1UqHnP0S1irXmMfOWoJNLgYsyYR4YYX4U/view" target="_blank" rel="noopener noreferrer" className="about-link">
                    Designing Inclusive Digital Solutions And Developing Digital Skills
                  </a>
                  
                  <a href="https://www.youtube.com/watch?v=Fo-1qrHeQXQ" target="_blank" rel="noopener noreferrer" className="about-link">
                    Designing Great Apps For New Internet Users
                  </a>
                  <a href="https://soundcloud.com/studio34flipkart/how-flipkart-is-solving-for-its-next-100-million-customers" target="_blank" rel="noopener noreferrer" className="about-link">
                    How Flipkart Is Solving For Its Next 100 Million Customers
                  </a>
        
                </div>
              </Col>
              <Col className="text-left relative" md={{ span: 2 }} xs={{ span: 5 }}>
                <div className="text-6 margin-bottom-me">
                  <a href="https://www.cgap.org/research/slide-deck/smartphones-and-mobile-money-principles-uiux-design-10" target="_blank" rel="noopener noreferrer" className="about-link">
                    Smartphones And Mobile Money: Principles For UI/UX Design
                  </a>
                  <a href="https://www.youtube.com/watch?v=6fBS8LOpIQc" target="_blank" rel="noopener noreferrer" className="about-link">
                    Challenges And Learnings Of Building For The Next Billion Users
                  </a>

                  <a href="https://design.google/library/sketch-scroll-or-swipe/" target="_blank" rel="noopener noreferrer" className="about-link">
                    Sketch, Scroll, Or Swipe? Choosing The Right Prototyping Method
                  </a>
                  <a href="https://jiny.io/blog/understanding-bharat/" target="_blank" rel="noopener noreferrer" className="about-link">
                    Understanding Bharat: Unlocking A $100B Opportunity
                  </a>

                  <a href="https://myoralvillage.org/about/" target="_blank" rel="noopener noreferrer" className="about-link">
                    My Oral Village
                  </a>
                  <a href="https://www.youtube.com/watch?v=UPZPpuWNCp8" target="_blank" rel="noopener noreferrer" className="about-link">
                  Build Apps For The Next Billion Users
                  </a>

                  <a href="https://vimeo.com/254843191" target="_blank" rel="noopener noreferrer" className="about-link">
                  The Universal Designer: What You Can Learn From Emerging Markets
                  </a>
                  <a href="https://www.youtube.com/watch?time_continue=14&v=BvqGxz-hMH0" target="_blank" rel="noopener noreferrer" className="about-link">
                  Field-tested Interfaces For The Next Billion
                  </a>
                  
                  <a href="https://www.cgap.org/research/slide-deck/financial-services-apps-india" target="_blank" rel="noopener noreferrer" className="about-link">
                  Financial Services App In India: How To Improve The UX
                  </a>
                  <a href="https://d91labs.org/" target="_blank" rel="noopener noreferrer" className="about-link">
                  D91 Labs
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <Container className="normal-section">
          <Row>
            <Col className="text-left relative" md={{ span: 4, offset: 1 }} xs={{ span: 10, offset: 1 }}>
              <div className="text-2 margin-bottom-me">Acknowledgements</div>
              <div className="margin-bottom-me">The Bill &amp; Melinda Gates Foundation is the core funder of Last Mile Money. From testing our tools to inspiring us with their stories, here are some of the individuals who have helped bring this work to life.</div>
            </Col>
          </Row>
          <Row className="margin-bottom-big">
            <Col className="text-left relative" md={{ span: 2, offset: 1 }} xs={{ span: 5, offset: 1 }}>
              <div className="bold-text text-title-margin-bottom">Core Team</div>

              <div className="text-title-margin-bottom">
                <div className="bold-text">IDEO</div>
                George Joseph<br />
                Ethan Klein<br />
                Kelsie Klaustermeier<br />
                Jason Fund<br />
              </div>

              <div className="text-title-margin-bottom">
                <div className="bold-text">IDEO.org</div>
                Adam Reineck<br />
                Nathalie Collins<br />
              </div>

              <div className="text-title-margin-bottom">
                <div className="bold-text">Google</div>
                Adriana Olmos<br />
                Annie Zhou<br />
                Jason Spielman<br />
                Jung Kim <br />
                Krishnapriya Dutta <br/>
              </div>

              <div className="text-title-margin-bottom">
                <div className="bold-text">Bill &amp; Melinda Gates Foundation</div>
                Dave Kim<br />
              </div>

            </Col>

            <Col className="text-left relative" md={{ span: 2, offset: 1 }} xs={{ span: 5 }}>
              <div className="bold-text text-title-margin-bottom">Extended Team</div>

              <div className="text-title-margin-bottom">
                <div className="bold-text">IDEO</div>
                Azeo Fables<br />
                Becca Carroll<br />
                Joe Gerber<br />
                John Won<br />
                Juho Parviainen<br />
                Kanika Kumar<br />
              </div>

              <div className="text-title-margin-bottom">
                <div className="bold-text">IDEO.org</div>
                Dagmawit Mengestu<br />
                Shalu Umapathy<br />
              </div>

              <div className="text-title-margin-bottom">
                <div className="bold-text">Google</div>
                Asif Baki<br />
                Erik Ninomiya<br />
                Feel Hwang<br />
                Ke Li<br />
                Tracey Lindsay Chan<br />
              </div>

            </Col>

            <Col className="text-left relative mobile-margin-top " md={{ span: 2, offset: 1 }} xs={{ span: 5, offset: 1 }}>
              <div className="bold-text text-title-margin-bottom">Input and Feedback</div>

              <div className="text-title-margin-bottom">
                Abhideep Kumar<br />
                Agha Ali Raza<br />
                Ajaitha Shah<br />
                Akhil Sehgal<br />
                Akshay Verma<br />
                Alessandra Millar<br />
                Anjali Balakrishna<br />
                Avani Agarwal<br />
                Balachandra Shetty<br />
                Ben Davies<br />
                Dharmesh BA<br />
                Gabe White<br />
                Indrani Medhi Thies<br />
                Kenneth DeSouza<br />
                Kentaro Toyama<br />
                Kishore Kumar<br />
                Kushagra Sinha<br />
                Manasi Kothari<br />
                Mrinal Sharma<br />

              </div>


            </Col>

            <Col className="text-left relative" md={{ span: 2, offset: 1 }} xs={{ span: 5 }}>
              <div className="bold-text text-title-margin-bottom invisible">Input and Feedback</div>

              <div className="text-title-margin-bottom mobile-margin-top-bit-more ">
                Nandini Stocker<br />
                Neeraj Jain<br />
                Nikhil Sharma<br />
                Lizann Fernandes<br />
                Lu De Pasquale<br />
                Prachi Nagpal<br />
                Pragati Mehrotra<br />
                Pratap KS<br />
                Rikin Gandhi<br />
                Sachin Sood<br />
                Sahil Sachdeva<br />
                Shama Rasal<br />
                Shrinath V<br />
                Soham Mondal<br />
                Sudha Padmanabhan<br />
                Sukmaji Kumoro<br />
                Tosh Juma<br />
                <br />
                <div className="bold-text">Photography</div>
                <div className="photo-credit">Bruno Contin (India homepage photos)</div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="text-left relative mobile-no-left-padding" xs={{ span: 10 }} md={{ span: 4, offset: 1 }}>
              <div className="about-2-container">
                <img className="about-photo" src={require('../img/about1-low.png')} alt="About"></img>
              </div>
            </Col>
            <Col className="text-left relative mobile-no-right-padding mobile-no-left-padding" md={{ span: 6}} >
              <div className=" gif-container">
                <img className="about-photo about2" src={require('../img/about2.png')} alt="About"></img>
                <img className="about-photo about3" src={require('../animated-gifs/Wave.gif')} alt="About"></img>
              </div>
              <img className="about-circle" src={require('../img/about-circle.png')} alt="About"></img>
            </Col>
          </Row>

        </Container>

        <Footer hideTopFooterSection="true" />

      </div>
    );
  }
}

export default About;
