import React from 'react';
import '../Tools/Tools.scss';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import toolsTopBack from '../img/tools/purple-header-focus.jpg';
import TopNav from '../TopNav/TopNav'
import Footer from '../Footer/Footer'
import DownloadButton from '../DownloadButton'

function FocusChallenge(props) {

  const updateAppEmail = (address) => {
    props.onEmailChange(address);
  }

  return (
    <div className="focus-challenge tools-subpage">
      <TopNav currentTopNav="tools" />
      <div className="page-top" style={{backgroundImage: `url(${toolsTopBack})`}}>
        <Container className="tools-top tools-subpage-top">
          <Row>
            <Col className="text-left relative" lg={{ span: 4, offset: 1 }}>
              <div className="text-5 tools-subpage-title">Team Activity</div>
              <div className="text-1 tools-subpage-subtitle">Focus your Challenge</div>
            </Col>
            <Col className="text-left relative" lg={{ span: 7 }}>
              <img className="tools-subpage-hero" src={require('../img/tools/focus-challenge-cards.png')} alt="Get Inspired"></img>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="normal-section tools-subpage-content">
        <Container>
          <Row className="margin-bottom-big">
            <Col md={{ span: 6, offset: 1 }}  xs={{ span: 10, offset: 1 }}>
              <div className="text-2">Whether you're running a sprint or defining your roadmap, the first step is aligning on where you'll focus</div>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 6, offset: 1 }}>
              <div className="tools-subpage-copy-container">
                <div className="tools-subpage-header">Step 1</div>
                Invite relevant team members and stakeholders to attend a one- to two-hour meeting. Let them know that the goal of your meeting is to start understanding and selecting a key focus area for your potential sprint.
              </div>

              <div className="tools-subpage-copy-container">
                <div className="tools-subpage-header">Step 2</div>
                During the meeting, spread a deck of <a id="focus-challenge-link" href="/tools/sprint-focus-cards">Sprint Focus Cards</a> on the table in front of the group. Read, discuss, and reflect on the cards together.
              </div>

              <div className="tools-subpage-copy-container">
                <div className="tools-subpage-header">Step 3</div>
                Select a few questions to discuss together:
                <ul className="tools-subpage-list">
                  <li>Are we already working on any of these focus areas?</li>
                  <li>Which focus areas might impact our business the most tomorrow? In one year?</li>
                  <li>Which focus areas are we equipped to design for? Which do we need help with?</li>
                  <li>If we could wave a magic wand and redesign one of these focus areas within our product tomorrow, which would we pick?</li>
                </ul>
              </div>

            </Col>
            <Col md={{ span: 3, offset: 1 }} xs={{ span: 10, offset: 1 }}>
              <div className="">
                <div className="tools-rightside-header text-5">Goal</div>
                Have an initial conversation about where to focus a design sprint
                <div className="tools-rightside-header">Time</div>
                1-2 hours
                <div className="tools-rightside-header">Attendees</div>
                Key team members and stakeholders
                <div className="tools-rightside-header">Materials</div>
                <DownloadButton onEmailChange={updateAppEmail} emailFromApp={props.emailFromApp} pdf="sprint-focus-cards" text="Download and Print" />
              </div>
            </Col>            
          </Row>
        </Container>
      </div>

      <Footer currentPage="focus" />

    </div>
  );
}

export default FocusChallenge;
