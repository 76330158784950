import React from 'react';
import '../Tools/Tools.scss';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import toolsTopBack from '../img/tools/orange-header-empathy.jpg';
// import empathyTopBack from '../img/tools/empathy-cards.png';
import TopNav from '../TopNav/TopNav'
import Footer from '../Footer/Footer'
import DownloadButton from '../DownloadButton'

function BuildEmpathy(props) {

  const updateAppEmail = (address) => {
    props.onEmailChange(address);
  }

  return (
    <div className="build-empathy tools-subpage">
      <TopNav currentTopNav="tools" />
      <div className="page-top" style={{backgroundImage: `url(${toolsTopBack})`}}>
        <Container className="tools-top tools-subpage-top">
          <Row>
            <Col className="text-left relative" lg={{ span: 4, offset: 1 }}>
              <div className="text-5 tools-subpage-title">Team Activity</div>
              <div className="text-1 tools-subpage-subtitle">Build Empathy</div>
            </Col>
            <Col className="text-left relative" lg={{ span: 7 }}>
              <img className="tools-subpage-hero" src={require('../img/tools/empathy-cards.png')} alt="empathy cards"></img>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="normal-section tools-subpage-content">
        <Container>
          <Row className="margin-bottom-big">
            <Col md={{ span: 6, offset: 1 }} xs={{ span: 10, offset: 1 }}>
              <div className="text-2">The next wave of internet users has unique needs and context</div>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 6, offset: 1 }}>
              <div className="tools-subpage-copy-container">
                <div className="tools-subpage-header">Step 1</div>
                Invite relevant team members and stakeholders to attend a one-to two-hour meeting. Let them know that the goal of your meeting is to build empathy and understanding for a new type of user.
              </div>

              <div className="tools-subpage-copy-container">
                <div className="tools-subpage-header">Step 2</div>
                During the meeting, spread a deck of <a id="build-empathy-link" href="/tools/user-context-cards">User Context Cards</a> on the table in front of the group. Read, discuss, and reflect on the cards together.
              </div>

              <div className="tools-subpage-copy-container">
                <div className="tools-subpage-header">Step 3</div>
                Select a few questions to discuss together:
                <ul className="tools-subpage-list">
                  <li>Do any of these cards feel relevant to our product?</li>
                  <li>Have we done any existing research that adds more texture to any of the cards?</li>
                  <li>Which of these cards feels most surprising?</li>
                  <li>What’s missing from this deck of cards?</li>
                  <li>If we could wave a magic wand and update our product to do a better job with one of these cards tomorrow, which card would we pick?</li>
                </ul>
              </div>

            </Col>
            <Col md={{ span: 3, offset: 1 }} xs={{ span: 10, offset: 1 }}>
              <div className="">
                <div className="tools-rightside-header text-5">Goal</div>
                Build empathy and understanding about your users among key stakeholders
                <div className="tools-rightside-header">Time</div>
                1-2 hours
                <div className="tools-rightside-header">Attendees</div>
                Key team members and stakeholders
                <div className="tools-rightside-header">Materials</div>
                <DownloadButton onEmailChange={updateAppEmail} emailFromApp={props.emailFromApp} pdf="user-context-cards" text="Download the Cards" />
               </div>
            </Col>            
          </Row>
        </Container>
      </div>

      <Footer currentPage="empathy" />

    </div>
  );
}

export default BuildEmpathy;
