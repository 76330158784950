import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import ScrollToTop from './ScrollToTop';
import Home from './Home/Home';
import About from './About/About';
import Opportunities from './Opportunities/Opportunities';
import Tools from './Tools/Tools';
import BuildEmpathy from './BuildEmpathy/BuildEmpathy';
import GetInspired from './GetInspired/GetInspired';
import FocusChallenge from './FocusChallenge/FocusChallenge';
import SprintCanvas from './Tools/SprintCanvas';
import SprintFocusCards from './Tools/SprintFocusCards';
import UserContextCards from './Tools/UserContextCards';
import DesignPrincipleCards from './Tools/DesignPrincipleCards';
import InspirationTool from './Tools/InspirationTool';
import TestFramework from './Tools/TestFramework';
import Provocations from './Tools/Provocations';
import Products from './Tools/Products';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {

  const [userEmailAddress, setUserEmailAddress] = useState("");

  const updateEmail = (address) => {
    setUserEmailAddress(address);
  }  

  return (
    <div className="lmm">
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path="/opportunities">
            <Opportunities />
          </Route>
          <Route path="/tools/build-empathy">
            <BuildEmpathy emailFromApp={userEmailAddress} onEmailChange={updateEmail} />
          </Route>
          <Route path="/tools/get-inspired">
            <GetInspired  />
          </Route>
          <Route path="/tools/focus-challenge">
            <FocusChallenge emailFromApp={userEmailAddress} onEmailChange={updateEmail} />
          </Route>
          <Route path="/tools/sprint-canvas">
            <SprintCanvas emailFromApp={userEmailAddress} onEmailChange={updateEmail} />
          </Route>
          <Route path="/tools/sprint-focus-cards">
            <SprintFocusCards emailFromApp={userEmailAddress} onEmailChange={updateEmail} />
          </Route>
          <Route path="/tools/user-context-cards">
            <UserContextCards emailFromApp={userEmailAddress} onEmailChange={updateEmail} />
          </Route>
          <Route path="/tools/design-principle-cards">
            <DesignPrincipleCards emailFromApp={userEmailAddress} onEmailChange={updateEmail} />
          </Route>
          <Route path="/tools/inspiration-tool">
            <InspirationTool />
          </Route>
          <Route path="/tools/test-framework">
            <TestFramework emailFromApp={userEmailAddress} onEmailChange={updateEmail} />
          </Route>
          <Route path="/tools/provocations">
            <Provocations />
          </Route>
          <Route path="/tools/products">
            <Products />
          </Route>
          <Route path="/tools">
            <Tools />
          </Route>
          <Route path="/about">
            <About />
          </Route>            
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
