import React, { Component } from 'react';
import './Tools.scss';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import TopNav from '../TopNav/TopNav'
import Footer from '../Footer/Footer'
import {
  Link as LinkDom
} from "react-router-dom";

import { Link, Element, scrollSpy } from 'react-scroll';
import { Waypoint } from 'react-waypoint';

import { withRouter } from "react-router-dom";

import proOnboarding1 from '../img/tools/pro/1x/ProvocationOnboarding01.png';
import proOnboarding2 from '../img/tools/pro/1x/ProvocationOnboarding02.png';
import proOnboarding3 from '../img/tools/pro/1x/ProvocationOnboarding03.png';
import proOnboarding4 from '../img/tools/pro/1x/ProvocationOnboarding04.png';
import proOnboarding5 from '../img/tools/pro/1x/ProvocationOnboarding05.png';

import proNavigation1 from '../img/tools/pro/1x/ProvocationNavigation01.png';
import proNavigation2 from '../img/tools/pro/1x/ProvocationNavigation02.png';
import proNavigation3 from '../img/tools/pro/1x/ProvocationNavigation03.png';
import proNavigation4 from '../img/tools/pro/1x/ProvocationNavigation04.png';
import proNavigation5 from '../img/tools/pro/1x/ProvocationNavigation05.png';

import proAccounts1 from '../img/tools/pro/1x/ProvocationAccount01.png';
import proAccounts2 from '../img/tools/pro/1x/ProvocationAccount02.png';
import proAccounts3 from '../img/tools/pro/1x/ProvocationAccount03.png';
import proAccounts4 from '../img/tools/pro/1x/ProvocationAccount04.png';
import proAccounts5 from '../img/tools/pro/1x/ProvocationAccount05.png';

import proDiscovery1 from '../img/tools/pro/1x/ProvocationDiscovery01.png';
import proDiscovery2 from '../img/tools/pro/1x/ProvocationDiscovery02.png';
import proDiscovery3 from '../img/tools/pro/1x/ProvocationDiscovery03.png';
import proDiscovery4 from '../img/tools/pro/1x/ProvocationDiscovery04.png';
import proDiscovery5 from '../img/tools/pro/1x/ProvocationDiscovery05.png';

import proAssistance1 from '../img/tools/pro/1x/ProvocationAssistance01.png';
import proAssistance2 from '../img/tools/pro/1x/ProvocationAssistance02.png';
import proAssistance3 from '../img/tools/pro/1x/ProvocationAssistance03.png';
import proAssistance4 from '../img/tools/pro/1x/ProvocationAssistance04.png';
import proAssistance5 from '../img/tools/pro/1x/ProvocationAssistance05.png';

import proVoice1 from '../img/tools/pro/1x/ProvocationVoice01.png';
import proVoice2 from '../img/tools/pro/1x/ProvocationVoice02.png';
import proVoice3 from '../img/tools/pro/1x/ProvocationVoice03.png';
import proVoice4 from '../img/tools/pro/1x/ProvocationVoice04.png';
import proVoice5 from '../img/tools/pro/1x/ProvocationVoice05.png';

import proVisuals1 from '../img/tools/pro/1x/ProvocationVisuals01.png';
import proVisuals2 from '../img/tools/pro/1x/ProvocationVisuals02.png';
import proVisuals3 from '../img/tools/pro/1x/ProvocationVisuals03.png';
import proVisuals4 from '../img/tools/pro/1x/ProvocationVisuals04.png';
import proVisuals5 from '../img/tools/pro/1x/ProvocationVisuals05.png';

import proLanguage1 from '../img/tools/pro/1x/ProvocationLanguage01.png';
import proLanguage2 from '../img/tools/pro/1x/ProvocationLanguage02.png';
import proLanguage3 from '../img/tools/pro/1x/ProvocationLanguage03.png';
import proLanguage4 from '../img/tools/pro/1x/ProvocationLanguage04.png';
import proLanguage5 from '../img/tools/pro/1x/ProvocationLanguage05.png';

import proTransactions1 from '../img/tools/pro/1x/ProvocationTransactions01.png';
import proTransactions2 from '../img/tools/pro/1x/ProvocationTransactions02.png';
import proTransactions3 from '../img/tools/pro/1x/ProvocationTransactions03.png';
import proTransactions4 from '../img/tools/pro/1x/ProvocationTransactions04.png';
import proTransactions5 from '../img/tools/pro/1x/ProvocationTransactions05.png';

import proData1 from '../img/tools/pro/1x/ProvocationData01.png';
import proData2 from '../img/tools/pro/1x/ProvocationData02.png';
import proData3 from '../img/tools/pro/1x/ProvocationData03.png';
import proData4 from '../img/tools/pro/1x/ProvocationData04.png';
import proData5 from '../img/tools/pro/1x/ProvocationData05.png';


class Provocations extends Component {
  constructor(props) {
    super(props);
    this.scrollContainer = React.createRef();

    scrollSpy.update();

    this.state = {
      currentNavSection: "assistance",
      currentDotPage: 1,
      isAssistanceOpen: true,
      isLanguageOpen: false,
      isVoiceOpen: false,
      isOnboardingOpen: false,
      isNavigationOpen: false,
      isAccountsOpen: false,
      isDiscoveryOpen: false,
      isVisualsOpen: false,
      isTransactionsOpen: false,
      isDataOpen: false,
    }
  }

  scrollSpeed = 500;
  bottomOffsetValue = "1000px";

  componentDidMount() {
    // console.log('componentmounted');
  }

  moreToolsClick = (whichTool) => {
    // console.log(whichTool)
  }

  currentlyInView = (section) => {
    this.setState({currentNavSection: section});
  }


  handleWaypointEnter = (page, obj) => {
    this.setState({currentDotPage: page});
  }

  getAssistanceSectionClass = () => {
    if (this.state.isAssistanceOpen) {
      return "section-container open"
    } else {
      return "section-container"
    }
  }

  getLanguageSectionClass = () => {
    if (this.state.isLanguageOpen) {
      return "section-container open"
    } else {
      return "section-container"
    }
  }

  getVoiceSectionClass = () => {
    if (this.state.isVoiceOpen) {
      return "section-container open"
    } else {
      return "section-container"
    }
  }

  getOnboardingSectionClass = () => {
    if (this.state.isOnboardingOpen) {
      return "section-container open"
    } else {
      return "section-container"
    }
  }

  getNavigationSectionClass = () => {
    if (this.state.isNavigationOpen) {
      return "section-container open"
    } else {
      return "section-container"
    }
  }

  getAccountsSectionClass = () => {
    if (this.state.isAccountsOpen) {
      return "section-container open"
    } else {
      return "section-container"
    }
  }

  getDiscoverySectionClass = () => {
    if (this.state.isDiscoveryOpen) {
      return "section-container open"
    } else {
      return "section-container"
    }
  }

  getVisualsSectionClass = () => {
    if (this.state.isVisualsOpen) {
      return "section-container open"
    } else {
      return "section-container"
    }
  }

  getTransactionsSectionClass = () => {
    if (this.state.isTransactionsOpen) {
      return "section-container open"
    } else {
      return "section-container"
    }
  }

  getDataSectionClass = () => {
    if (this.state.isDataOpen) {
      return "section-container open"
    } else {
      return "section-container"
    }
  }

  mobileSectionTap = (section) => {

    if (section === "assistance") {
      if (this.state.isAssistanceOpen === false) {
        this.setState({isAssistanceOpen: true});
      } else {
        this.setState({isAssistanceOpen: false});
      }
    }
    if (section === "language") {
      if (this.state.isLanguageOpen === false) {
        this.setState({isLanguageOpen: true});
      } else {
        this.setState({isLanguageOpen: false});
      }
    }

    if (section === "onboarding") {
      if (this.state.isOnboardingOpen === false) {
        this.setState({isOnboardingOpen: true});
      } else {
        this.setState({isOnboardingOpen: false});
      }
    }
    if (section === "navigation") {
      if (this.state.isNavigationOpen === false) {
        this.setState({isNavigationOpen: true});
      } else {
        this.setState({isNavigationOpen: false});
      }
    }

    if (section === "accounts") {
      if (this.state.isAccountsOpen === false) {
        this.setState({isAccountsOpen: true});
      } else {
        this.setState({isAccountsOpen: false});
      }
    }
    if (section === "discovery") {
      if (this.state.isDiscoveryOpen === false) {
        this.setState({isDiscoveryOpen: true});
      } else {
        this.setState({isDiscoveryOpen: false});
      }
    }

    if (section === "visuals") {
      if (this.state.isVisualsOpen === false) {
        this.setState({isVisualsOpen: true});
      } else {
        this.setState({isVisualsOpen: false});
      }
    }

    if (section === "voice") {
      if (this.state.isVoiceOpen === false) {
        this.setState({isVoiceOpen: true});
      } else {
        this.setState({isVoiceOpen: false});
      }
    }

    
    if (section === "transactions") {
      if (this.state.isTransactionsOpen === false) {
        this.setState({isTransactionsOpen: true});
      } else {
        this.setState({isTransactionsOpen: false});
      }
    }

    if (section === "data") {
      if (this.state.isDataOpen === false) {
        this.setState({isDataOpen: true});
      } else {
        this.setState({isDataOpen: false});
      }
    }
  }

  getDotClass = (page) => {
    // console.log('page: ', page);
    // console.log('this.state.currentDotPage: ', this.state.currentDotPage);
    if (page <= this.state.currentDotPage) {
      return "dot on"      
    } else {
      return "dot"
    }
  }

  render() {
    return (
      <div className="provocations">
        <TopNav currentTopNav="tools" />

        <Container className="page-top-space pro-container">
          <Row>
            <Col className="text-left" lg={{span: 3, offset: 1}}>
              <div className="pro-nav-top-buttons-container">
                <div className="pro-nav-top-button selected text-6 bold-text">Provocations</div>
                <LinkDom to="/tools/products" className="pro-nav-top-button text-6 bold-text">
                  Products
                </LinkDom>
              </div>


              <div className="hide-if-mobile">
                <Link activeClass="selected" className="pro-nav-item" to="assistance-section-container" containerId="scroll-within" spy={true} smooth={true} duration={this.scrollSpeed}>
                  <img className="pro-icon" src={require('../img/icons/assistance-icon.png')} alt="assistance"></img>
                  Assistance
                </Link>

                <Link activeClass="selected" className="pro-nav-item" to="language-section-container" containerId="scroll-within" spy={true} smooth={true} duration={this.scrollSpeed}>
                  <img className="pro-icon" src={require('../img/icons/language-icon.png')} alt="language"></img>
                  Language
                </Link>

                <Link activeClass="selected" className="pro-nav-item" to="voice-section-container" containerId="scroll-within" spy={true} smooth={true} duration={this.scrollSpeed}>
                  <img className="pro-icon" src={require('../img/icons/voice-icon.png')} alt="voice"></img>
                  Voice
                </Link>

                <Link activeClass="selected" className="pro-nav-item"  to="onboarding-section-container" containerId="scroll-within" spy={true} smooth={true} duration={this.scrollSpeed}>
                  <img className="pro-icon" src={require('../img/icons/onboarding-icon.png')} alt="onboarding"></img>
                  Onboarding
                </Link>
                <Link activeClass="selected" className="pro-nav-item" to="navigation-section-container" containerId="scroll-within" spy={true} smooth={true} duration={this.scrollSpeed}>
                  <img className="pro-icon" src={require('../img/icons/navigation.png')} alt="navigation"></img>
                  Navigation
                </Link>
                <Link activeClass="selected" className="pro-nav-item" to="accounts-section-container" containerId="scroll-within" spy={true} smooth={true} duration={this.scrollSpeed}>
                  <img className="pro-icon" src={require('../img/icons/accounts.png')} alt="accounts"></img>
                  Accounts
                </Link>
                <Link activeClass="selected" className="pro-nav-item" to="discovery-section-container" containerId="scroll-within" spy={true} smooth={true} duration={this.scrollSpeed}>
                  <img className="pro-icon" src={require('../img/icons/discovery.png')} alt="discovery"></img>
                  Discovery
                </Link>

                <Link activeClass="selected" className="pro-nav-item" to="visuals-section-container" containerId="scroll-within" spy={true} smooth={true} duration={this.scrollSpeed}>
                  <img className="pro-icon" src={require('../img/icons/visuals-icon.png')} alt="visuals"></img>
                  Visuals
                </Link>


                <Link activeClass="selected" className="pro-nav-item" to="transactions-section-container" containerId="scroll-within" spy={true} smooth={true} duration={this.scrollSpeed}>
                  <img className="pro-icon" src={require('../img/icons/transactions-icon.png')} alt="transactions"></img>
                  Transactions
                </Link>

                <Link activeClass="selected" className="pro-nav-item" to="data-section-container" containerId="scroll-within" spy={true} smooth={true} duration={this.scrollSpeed}>
                  <img className="pro-icon" src={require('../img/icons/data-icon.png')} alt="data"></img>
                  Data
                </Link>
              </div>
            </Col>
            <Col className="relative" lg={{span: 8}}>

              <div className="page-dots-container hide-if-mobile">
                <div className={this.getDotClass(1)}></div>
                <div className={this.getDotClass(2)}></div>
                <div className={this.getDotClass(3)}></div>
                <div className={this.getDotClass(4)}></div>
                <div className={this.getDotClass(5)}></div>
              </div>

              <div className="scroll-within" id="scroll-within" ref={this.scrollContainer}>

                <div className="pro-nav-item show-if-mobile" onClick={() => this.mobileSectionTap('assistance')}>
                  <img className="pro-icon" src={require('../img/icons/assistance-icon.png')} alt="assistance"></img>
                  Assistance
                </div>

                {/* Assistance Section */}
                <Element name="assistance-section-container" className={this.getAssistanceSectionClass()}>
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(1, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proAssistance1})`}}>
                    <div className="pro-content-text">
                    What if there was always a clear, consistent place to get help or ask a question?
                    </div>
                  </div>

                  {/* Assistance 2 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(2, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proAssistance2})`}}>
                    <div className="pro-content-text">
                    What if assistance felt like a natural part of the flow and not like a failure or embarrassment?
                    </div>
                  </div>
        
                  {/* Assistance 3 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(3, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proAssistance3})`}}>
                    <div className="pro-content-text">
                    What if we could show users complex interactions before they’re required to perform them?
                    </div>
                  </div>

                  {/* Assistance 4 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(4, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proAssistance4})`}}>
                    <div className="pro-content-text">
                    What if we incorporated opportunities for users to consult with a real person? 
                    </div>
                  </div>

                  {/* Assistance 5 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(5, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proAssistance5})`}}>
                    <div className="pro-content-text">
                    What if users could always “undo” any action they take?
                    </div>
                  </div>
                </Element>


                <div className="pro-nav-item show-if-mobile" onClick={() => this.mobileSectionTap('language')}>
                  <img className="pro-icon" src={require('../img/icons/language-icon.png')} alt="language"></img>
                  Language
                </div>


                {/* Language Section */}
                <Element name="language-section-container" className={this.getLanguageSectionClass()}>
                  {/* Language 1 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(1, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proLanguage1})`}}>
                    <div className="pro-content-text">
                    What if users could temporarily override the language that content is being presented in?
                    </div>
                  </div>

                  {/* Language 2 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(2, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proLanguage2})`}}>
                    <div className="pro-content-text">
                    What if users could translate individual words or short phrases they don’t understand?
                    </div>
                  </div>
        
                  {/* Language 3 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(3, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proLanguage3})`}}>
                    <div className="pro-content-text">
                    What if text could always be read aloud?
                    </div>
                  </div>

                  {/* Language 4 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(4, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proLanguage4})`}}>
                    <div className="pro-content-text">
                    What if it was possible to show more than one language at the same time?
                    </div>
                  </div>

                  {/* Language 5 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(5, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proLanguage5})`}}>
                    <div className="pro-content-text">
                    What if your app’s language setting could be controlled separately from the device language setting?
                    </div>
                  </div>
                </Element>


                <div className="pro-nav-item show-if-mobile" onClick={() => this.mobileSectionTap('voice')}>
                  <img className="pro-icon" src={require('../img/icons/voice-icon.png')} alt="voice"></img>
                  Voice
                </div>


                {/* Voice Section */}
                <Element name="voice-section-container" className={this.getVoiceSectionClass()}> 
                  {/* Voice 1 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(1, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proVoice1})`}}>
                    <div className="pro-content-text">
                    What if we provided hints to guide voice inputs?
                    </div>
                  </div>

                  {/* Voice 2 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(2, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proVoice2})`}}>
                    <div className="pro-content-text">
                    What if users could input multiple languages within an interaction?
                    </div>
                  </div>
        
                  {/* Voice 3 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(3, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proVoice3})`}}>
                    <div className="pro-content-text">
                    What if there was an easy way to correct voice inputs?
                    </div>
                  </div>

                  {/* Voice 4 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(4, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proVoice4})`}}>
                    <div className="pro-content-text">
                    What if the interface could still help even if it misunderstands a user? 
                    </div>
                  </div>

                  {/* Voice 5 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(5, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proVoice5})`}}>
                    <div className="pro-content-text">
                    What if voice was the primary mode of interaction?
                    </div>
                  </div>
                </Element>


                <div className="pro-nav-item show-if-mobile" onClick={() => this.mobileSectionTap('onboarding')}>
                  <img className="pro-icon" src={require('../img/icons/onboarding-icon.png')} alt="onboarding"></img>
                  Onboarding
                </div>


                {/* Onboarding Section */}
                <Element name="onboarding-section-container" className={this.getOnboardingSectionClass()}>
                  {/* Onboarding 1 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(1, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proOnboarding1})`}}>
                    <div className="pro-content-text">
                      What if onboarding always happened in context, instead of all at once or upfront?
                    </div>
                  </div>


                  {/* Onboarding 2 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(2, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proOnboarding2})`}}>
                    <div className="pro-content-text">
                      What if people could try the experience before or even without onboarding?
                    </div>
                  </div>

        
                  {/* Onboarding 3 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(3, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proOnboarding3})`}}>
                    <div className="pro-content-text">
                      What if we used UI hints to guide users instead of onboarding them to each feature?
                    </div>
                  </div>


                  {/* Onboarding 4 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(4, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proOnboarding4})`}}>
                    <div className="pro-content-text">
                      What if we could shorten big tasks into manageable sections?
                    </div>
                  </div>


                  {/* Onboarding 5 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(5, obj)}></Waypoint>
                    <div className="pro-content-container text-3" style={{backgroundImage: `url(${proOnboarding5})`}}>
                      <div className="pro-content-text">
                        What if users could personalize the app’s onboarding flow to match their needs or ability?
                      </div>
                    </div>
                </Element>


                <div className="pro-nav-item show-if-mobile" onClick={() => this.mobileSectionTap('navigation')}>
                  <img className="pro-icon" src={require('../img/icons/navigation.png')} alt="navigation"></img>
                  Navigation
                </div>


                {/* Navigation Section */}
                <Element name="navigation-section-container" className={this.getNavigationSectionClass()}>
                  {/* Navigation 1 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(1, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proNavigation1})`}}>
                    <div className="pro-content-text">
                      What if users could request a feature instead of navigating to it?
                    </div>
                  </div>
                  
                  {/* Navigation 2 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(2, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proNavigation2})`}}>
                    <div className="pro-content-text">
                      What if there are no hidden or nested features?
                    </div>
                  </div>
                  
                  {/* Navigation 3 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(3, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proNavigation3})`}}>
                    <div className="pro-content-text">
                      What if we could recommend content, so users needed to navigate less?
                    </div>
                  </div>

                  {/* Navigation 4 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(4, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proNavigation4})`}}>
                    <div className="pro-content-text">
                      What if we could proactively help users find new features?
                    </div>
                  </div>

                  {/* Navigation 5 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(5, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proNavigation5})`}}>
                    <div className="pro-content-text">
                      What if users were able to simplify every action into a single voice command?
                    </div>
                  </div>   
                </Element>


                <div className="pro-nav-item show-if-mobile" onClick={() => this.mobileSectionTap('accounts')}>
                  <img className="pro-icon" src={require('../img/icons/accounts.png')} alt="accounts"></img>
                  Accounts
                </div>

                {/* Accounts Section */}
                <Element name="accounts-section-container" className={this.getAccountsSectionClass()}>
                  {/* Accounts 1 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(1, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proAccounts1})`}}>
                    <div className="pro-content-text">
                      What if users saw immediate value before needing to sign in?
                    </div>
                  </div>

                  {/* Accounts 2 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(2, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proAccounts2})`}}>
                    <div className="pro-content-text">
                      What if there was no such thing as a password?
                    </div>
                  </div>
        
                  {/* Accounts 3 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(3, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proAccounts3})`}}>
                    <div className="pro-content-text">
                      What if signing in was intuitive and playful?
                    </div>
                  </div>

                  {/* Accounts 4 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(4, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proAccounts4})`}}>
                    <div className="pro-content-text">
                      What if devices could be easily shared among family members?
                    </div>
                  </div>

                  {/* Accounts 5 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(5, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proAccounts5})`}}>
                    <div className="pro-content-text">
                      What if account information was pre-populated to reduce friction during sign-up?
                    </div>
                  </div>
                </Element>

                <div className="pro-nav-item show-if-mobile" onClick={() => this.mobileSectionTap('discovery')}>
                  <img className="pro-icon" src={require('../img/icons/discovery.png')} alt="discovery"></img>
                  Discovery
                </div>

                {/* Discovery Section */}
                <Element name="discovery-section-container" className={this.getDiscoverySectionClass()}>
                  {/* Discovery 1 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(1, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proDiscovery1})`}}>
                    <div className="pro-content-text">
                    What if there were no hidden features?
                    </div>
                  </div>

                  {/* Discovery 2 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(2, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proDiscovery2})`}}>
                    <div className="pro-content-text">
                    What if contextual UI cues helped propel users forward?
                    </div>
                  </div>
        
                  {/* Discovery 3 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(3, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proDiscovery3})`}}>
                    <div className="pro-content-text">
                    What if errors always felt actionable?
                    </div>
                  </div>

                  {/* Discovery 4 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(4, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proDiscovery4})`}}>
                    <div className="pro-content-text">
                    What if new features were revealed to the user as they become more confident?
                    </div>
                  </div>

                  {/* Discovery 5 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(5, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proDiscovery5})`}}>
                    <div className="pro-content-text">
                    What if we proactively helped users discover new things?
                    </div>
                  </div>
                </Element>


                <div className="pro-nav-item show-if-mobile" onClick={() => this.mobileSectionTap('visuals')}>
                  <img className="pro-icon" src={require('../img/icons/visuals-icon.png')} alt="visuals"></img>
                  Visuals
                </div>

                {/* Visuals Section */}
                <Element name="visuals-section-container" className={this.getVisualsSectionClass()} >
                  {/* Visuals 1 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(1, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proVisuals1})`}}>
                    <div className="pro-content-text">
                    What if we could help users understand abstract concepts through visualizations?
                    </div>
                  </div>

                  {/* Visuals 2 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(2, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proVisuals2})`}}>
                    <div className="pro-content-text">
                    What if users could watch a peer perform an action before trying it themselves?
                    </div>
                  </div>
        
                  {/* Visuals 3 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(3, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proVisuals3})`}}>
                    <div className="pro-content-text">
                    What if the imagery we infused into our products felt inherently local?
                    </div>
                  </div>

                  {/* Visuals 4 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(4, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proVisuals4})`}}>
                    <div className="pro-content-text">
                    What if visuals could help users perform actions without having to read  anything at all?
                    </div>
                  </div>

                  {/* Visuals 5 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(5, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proVisuals5})`}}>
                    <div className="pro-content-text">
                    What if users could pick the type of media they’re most interested in?
                    </div>
                  </div>
                </Element>

                <div className="pro-nav-item show-if-mobile" onClick={() => this.mobileSectionTap('transactions')}>
                  <img className="pro-icon" src={require('../img/icons/transactions-icon.png')} alt="transactions"></img>
                  Transactions
                </div>

                {/* Transactions Section */}
                <Element name="transactions-section-container" className={this.getTransactionsSectionClass()}>
                  {/* Transactions 1 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(1, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proTransactions1})`}}>
                    <div className="pro-content-text">
                    What if we visualized analog financial artifacts to help users feel more confident about digital money?
                    </div>
                  </div>

                  {/* Transactions 2 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(2, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proTransactions2})`}}>
                    <div className="pro-content-text">
                    What if we could visualize abstract concepts to make them more understandable?
                    </div>
                  </div>
        
                  {/* Transactions 3 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(3, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proTransactions3})`}}>
                    <div className="pro-content-text">
                    What if we incorporated simple definitions for confusing terms?
                    </div>
                  </div>

                  {/* Transactions 4 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(4, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proTransactions4})`}}>
                    <div className="pro-content-text">
                    What if it was easy to secure personal details when asking others for help?
                    </div>
                  </div>

                  {/* Transactions 5 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(5, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proTransactions5})`}}>
                    <div className="pro-content-text">
                    What if we could show users we’re watching out for them?
                    </div>
                  </div>
                </Element>

                <div className="pro-nav-item show-if-mobile" onClick={() => this.mobileSectionTap('data')}>
                  <img className="pro-icon" src={require('../img/icons/data-icon.png')} alt="data"></img>
                  Data
                </div>

                {/* Data Section */}
                <Element name="data-section-container" className={this.getDataSectionClass()}>
                  {/* Data 1 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(1, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proData1})`}}>
                    <div className="pro-content-text">
                    What if there was always an action to take, even while offline?
                    </div>
                  </div>

                  {/* Data 2 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(2, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proData2})`}}>
                    <div className="pro-content-text">
                    What if users could define a limit for how much data their product should use?
                    </div>
                  </div>
        
                  {/* Data 3 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(3, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proData3})`}}>
                    <div className="pro-content-text">
                    What if users could easily compare the data costs of different actions?
                    </div>
                  </div>

                  {/* Data 4 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(4, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proData4})`}}>
                    <div className="pro-content-text">
                    What if users could download content when they’re online to view later, when they’re offline?
                    </div>
                  </div>

                  {/* Data 5 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(5, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proData5})`}}>
                    <div className="pro-content-text">
                    What if users could be nudged to optimize their storage space?
                    </div>
                  </div>
                </Element>

              </div>
            </Col>
          </Row>

          <Row>
            <Col>
               <div className="pro-nav-top-buttons-container pro-nav-top-buttons-bottom show-if-mobile">
                <div className="pro-nav-top-button selected text-6 bold-text">Provocations</div>
                <LinkDom to="/tools/products" className="pro-nav-top-button text-6 bold-text">
                  Products
                </LinkDom>
              </div>
            </Col>
          </Row>

        </Container>



        <Footer hideTopFooterSection={true} />


      </div>
    );
  }
}

export default withRouter(Provocations);
