import React, { Component } from 'react';
import './Tools.scss';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import TopNav from '../TopNav/TopNav'
import Footer from '../Footer/Footer'

import {  Link as LinkDom } from "react-router-dom";

import { Link, Element, scrollSpy } from 'react-scroll'
import { Waypoint } from 'react-waypoint';

import { withRouter } from "react-router-dom";

import proOnboarding1 from '../img/tools/pro/1x/SpotlightScreenOnboarding01.gif';
import proOnboarding2 from '../img/tools/pro/1x/SpotlightScreenOnboarding02.gif';
import proOnboarding3 from '../img/tools/pro/1x/SpotlightScreenOnboarding03.gif';
import proOnboarding4 from '../img/tools/pro/1x/SpotlightScreenOnboarding04.gif';
import proOnboarding5 from '../img/tools/pro/1x/SpotlightScreenOnboarding05.gif';

import proNavigation1 from '../img/tools/pro/1x/SpotlightScreenNavigation01.png';
import proNavigation2 from '../img/tools/pro/1x/SpotlightScreenNavigation02.gif';
import proNavigation3 from '../img/tools/pro/1x/SpotlightScreenNavigation03.png';
import proNavigation4 from '../img/tools/pro/1x/SpotlightScreenNavigation04.png';
import proNavigation5 from '../img/tools/pro/1x/SpotlightScreenNavigation05.png';

import proAccounts1 from '../img/tools/pro/1x/SpotlightScreenAccounts01.png';
import proAccounts2 from '../img/tools/pro/1x/SpotlightScreenAccounts02.png';
import proAccounts3 from '../img/tools/pro/1x/SpotlightScreenAccounts03.png';
import proAccounts4 from '../img/tools/pro/1x/SpotlightScreenAccounts04.png';
import proAccounts5 from '../img/tools/pro/1x/kormo-phone-mockx1.png';
import proAccountsKhata from '../img/tools/pro/1x/SpotlightScreen_Khatabook_Accounts2.png';

import proDiscovery1 from '../img/tools/pro/1x/SpotlightScreenDiscovery01.png';
import proDiscovery2 from '../img/tools/pro/1x/SpotlightScreenDiscovery02.png';
import proDiscovery3 from '../img/tools/pro/1x/SpotlightScreenDiscovery03.png';
import proDiscovery4 from '../img/tools/pro/1x/SpotlightScreenDiscovery04.png';
import proDiscovery5 from '../img/tools/pro/1x/SpotlightScreenDiscovery05.png';

import proAssistance1 from '../img/tools/pro/1x/SpotlightScreenAssistance01.png';
import proAssistance2 from '../img/tools/pro/1x/SpotlightScreenAssistance02.png';
import proAssistance3 from '../img/tools/pro/1x/SpotlightScreenAssistance03.gif';
import proAssistance4 from '../img/tools/pro/1x/SpotlightScreenAssistance04.gif';
import proAssistance5 from '../img/tools/pro/1x/SpotlightScreenAssistance05.gif';

import proVoice1 from '../img/tools/pro/1x/SpotlightScreenVoice01.png';
import proVoice2 from '../img/tools/pro/1x/SpotlightScreenVoice02.png';
import proVoice3 from '../img/tools/pro/1x/SpotlightScreenVoice03.png';
import proVoice4 from '../img/tools/pro/1x/SpotlightScreenVoice04.png';
import proVoice5 from '../img/tools/pro/1x/SpotlightScreenVoice05.gif';

import proVisuals1 from '../img/tools/pro/1x/SpotlightScreenVisuals01.png';
import proVisuals2 from '../img/tools/pro/1x/SpotlightScreenVisuals02.gif';
import proVisuals3 from '../img/tools/pro/1x/SpotlightScreenVisuals03.gif';
import proVisuals4 from '../img/tools/pro/1x/SpotlightScreenVisuals04.png';
import proVisuals5 from '../img/tools/pro/1x/SpotlightScreenVisuals05.png';
import proVisuals6 from '../img/tools/pro/1x/SpotlightScreenVisuals06.png';

import proLanguage1 from '../img/tools/pro/1x/SpotlightScreenLanguage01.png';
import proLanguage2 from '../img/tools/pro/1x/SpotlightScreenLanguage02.png';
import proLanguage3 from '../img/tools/pro/1x/SpotlightScreenLanguage03.png';
import proLanguage4 from '../img/tools/pro/1x/SpotlightScreenLanguage04.png';
import proLanguage5 from '../img/tools/pro/1x/SpotlightScreenLanguage05.png';
import proLanguage6 from '../img/tools/pro/1x/SpotlightScreenLanguage06.png';

import proTransactions1 from '../img/tools/pro/1x/SpotlightScreenTransactions01.png';
import proTransactions2 from '../img/tools/pro/1x/SpotlightScreenTransactions02.png';
import proTransactions3 from '../img/tools/pro/1x/SpotlightScreenTransactions03.png';
import proTransactions4 from '../img/tools/pro/1x/SpotlightScreenTransactions04.png';
import proTransactions5 from '../img/tools/pro/1x/SpotlightScreenTransactions05.png';

import proData1 from '../img/tools/pro/1x/SpotlightScreenData01.png';
import proData2 from '../img/tools/pro/1x/SpotlightScreenData02.png';
import proData3 from '../img/tools/pro/1x/SpotlightScreenData03.png';
import proData4 from '../img/tools/pro/1x/SpotlightScreenData04.gif';
import proData5 from '../img/tools/pro/1x/SpotlightScreenData05.png';


class Products extends Component {
  constructor(props) {
    super(props);
    this.scrollContainer = React.createRef();

    scrollSpy.update();

    this.state = {
      currentNavSection: "onboarding",
      currentDotPage: 1,
      isAssistanceOpen: true,
      isLanguageOpen: false,
      isVoiceOpen: false,
      isOnboardingOpen: false,
      isNavigationOpen: false,
      isAccountsOpen: false,
      isDiscoveryOpen: false,
      isVisualsOpen: false,
      isTransactionsOpen: false,
      isDataOpen: false,
    }
  }

  scrollSpeed = 500;
  bottomOffsetValue = "1000px";

  componentDidMount() {
    // console.log('componentmounted');
  }

  moreToolsClick = (whichTool) => {
    // console.log(whichTool)
  }

  currentlyInView = (section) => {
    this.setState({currentNavSection: section});
  }

  handleWaypointEnter = (page, obj) => {
    this.setState({currentDotPage: page});
  }

  getAssistanceSectionClass = () => {
    if (this.state.isAssistanceOpen) {
      return "section-container open"
    } else {
      return "section-container"
    }
  }

  getLanguageSectionClass = () => {
    if (this.state.isLanguageOpen) {
      return "section-container open"
    } else {
      return "section-container"
    }
  }

  getVoiceSectionClass = () => {
    if (this.state.isVoiceOpen) {
      return "section-container open"
    } else {
      return "section-container"
    }
  }

  getOnboardingSectionClass = () => {
    if (this.state.isOnboardingOpen) {
      return "section-container open"
    } else {
      return "section-container"
    }
  }

  getNavigationSectionClass = () => {
    if (this.state.isNavigationOpen) {
      return "section-container open"
    } else {
      return "section-container"
    }
  }

  getAccountsSectionClass = () => {
    if (this.state.isAccountsOpen) {
      return "section-container open"
    } else {
      return "section-container"
    }
  }

  getDiscoverySectionClass = () => {
    if (this.state.isDiscoveryOpen) {
      return "section-container open"
    } else {
      return "section-container"
    }
  }

  getVisualsSectionClass = () => {
    if (this.state.isVisualsOpen) {
      return "section-container open"
    } else {
      return "section-container"
    }
  }

  getTransactionsSectionClass = () => {
    if (this.state.isTransactionsOpen) {
      return "section-container open"
    } else {
      return "section-container"
    }
  }

  getDataSectionClass = () => {
    if (this.state.isDataOpen) {
      return "section-container open"
    } else {
      return "section-container"
    }
  }

  mobileSectionTap = (section) => {

    if (section === "assistance") {
      if (this.state.isAssistanceOpen === false) {
        this.setState({isAssistanceOpen: true});
      } else {
        this.setState({isAssistanceOpen: false});
      }
    }
    if (section === "language") {
      if (this.state.isLanguageOpen === false) {
        this.setState({isLanguageOpen: true});
      } else {
        this.setState({isLanguageOpen: false});
      }
    }

    if (section === "onboarding") {
      if (this.state.isOnboardingOpen === false) {
        this.setState({isOnboardingOpen: true});
      } else {
        this.setState({isOnboardingOpen: false});
      }
    }
    if (section === "navigation") {
      if (this.state.isNavigationOpen === false) {
        this.setState({isNavigationOpen: true});
      } else {
        this.setState({isNavigationOpen: false});
      }
    }

    if (section === "accounts") {
      if (this.state.isAccountsOpen === false) {
        this.setState({isAccountsOpen: true});
      } else {
        this.setState({isAccountsOpen: false});
      }
    }
    if (section === "discovery") {
      if (this.state.isDiscoveryOpen === false) {
        this.setState({isDiscoveryOpen: true});
      } else {
        this.setState({isDiscoveryOpen: false});
      }
    }

    if (section === "visuals") {
      if (this.state.isVisualsOpen === false) {
        this.setState({isVisualsOpen: true});
      } else {
        this.setState({isVisualsOpen: false});
      }
    }

    if (section === "voice") {
      if (this.state.isVoiceOpen === false) {
        this.setState({isVoiceOpen: true});
      } else {
        this.setState({isVoiceOpen: false});
      }
    }

    
    if (section === "transactions") {
      if (this.state.isTransactionsOpen === false) {
        this.setState({isTransactionsOpen: true});
      } else {
        this.setState({isTransactionsOpen: false});
      }
    }

    if (section === "data") {
      if (this.state.isDataOpen === false) {
        this.setState({isDataOpen: true});
      } else {
        this.setState({isDataOpen: false});
      }
    }
  }

  getDotClass = (page) => {
    // console.log('page: ', page);
    // console.log('this.state.currentDotPage: ', this.state.currentDotPage);
    if (page <= this.state.currentDotPage) {
      return "dot on"      
    } else {
      return "dot"
    }
  }

  render() {
    return (
      <div className="products">
        <TopNav currentTopNav="tools" />

        <div className="tools-mobile-overlay">
          <div className="overlay-content">Please open on a larger screen.</div>
        </div>

        <Container className="page-top-space pro-container">
          <Row>
            <Col className="text-left" lg={{span: 3, offset: 1}}>
              <div className="pro-nav-top-buttons-container">
                <LinkDom to="/tools/provocations" className="pro-nav-top-button text-6 bold-text">
                  Provocations
                </LinkDom>
                <div className="pro-nav-top-button selected text-6 bold-text">Products</div>
              </div>

              <div className="hide-if-mobile">

                <Link activeClass="selected" className="pro-nav-item" to="assistance-section-container" containerId="scroll-within" spy={true} smooth={true} duration={this.scrollSpeed}>
                  <img className="pro-icon" src={require('../img/icons/assistance-icon.png')} alt="assistance"></img>
                  Assistance
                </Link>

                <Link activeClass="selected" className="pro-nav-item" to="language-section-container" containerId="scroll-within" spy={true} smooth={true} duration={this.scrollSpeed}>
                  <img className="pro-icon" src={require('../img/icons/language-icon.png')} alt="language"></img>
                  Language
                </Link>

                <Link activeClass="selected" className="pro-nav-item" to="voice-section-container" containerId="scroll-within" spy={true} smooth={true} duration={this.scrollSpeed}>
                  <img className="pro-icon" src={require('../img/icons/voice-icon.png')} alt="voice"></img>
                  Voice
                </Link>

                <Link activeClass="selected" className="pro-nav-item"  to="onboarding-section-container" containerId="scroll-within" spy={true} smooth={true} duration={this.scrollSpeed}>
                  <img className="pro-icon" src={require('../img/icons/onboarding-icon.png')} alt="onboarding"></img>
                  Onboarding
                </Link>
                <Link activeClass="selected" className="pro-nav-item" to="navigation-section-container" containerId="scroll-within" spy={true} smooth={true} duration={this.scrollSpeed}>
                  <img className="pro-icon" src={require('../img/icons/navigation.png')} alt="navigation"></img>
                  Navigation
                </Link>
                <Link activeClass="selected" className="pro-nav-item" to="accounts-section-container" containerId="scroll-within" spy={true} smooth={true} duration={this.scrollSpeed}>
                  <img className="pro-icon" src={require('../img/icons/accounts.png')} alt="accounts"></img>
                  Accounts
                </Link>
                <Link activeClass="selected" className="pro-nav-item" to="discovery-section-container" containerId="scroll-within" spy={true} smooth={true} duration={this.scrollSpeed}>
                  <img className="pro-icon" src={require('../img/icons/discovery.png')} alt="discovery"></img>
                  Discovery
                </Link>

                <Link activeClass="selected" className="pro-nav-item" to="visuals-section-container" containerId="scroll-within" spy={true} smooth={true} duration={this.scrollSpeed}>
                  <img className="pro-icon" src={require('../img/icons/visuals-icon.png')} alt="visuals"></img>
                  Visuals
                </Link>


                <Link activeClass="selected" className="pro-nav-item" to="transactions-section-container" containerId="scroll-within" spy={true} smooth={true} duration={this.scrollSpeed}>
                  <img className="pro-icon" src={require('../img/icons/transactions-icon.png')} alt="transactions"></img>
                  Transactions
                </Link>

                <Link activeClass="selected" className="pro-nav-item" to="data-section-container" containerId="scroll-within" spy={true} smooth={true} duration={this.scrollSpeed}>
                  <img className="pro-icon" src={require('../img/icons/data-icon.png')} alt="data"></img>
                  Data
                </Link>
              </div>
            </Col>
            <Col className="relative" lg={{span: 8}}>

              <div className="page-dots-container hide-if-mobile">
                <div className={this.getDotClass(1)}></div>
                <div className={this.getDotClass(2)}></div>
                <div className={this.getDotClass(3)}></div>
                <div className={this.getDotClass(4)}></div>
              </div>

              <div className="scroll-within" id="scroll-within" ref={this.scrollContainer}>



                <div className="pro-nav-item show-if-mobile" onClick={() => this.mobileSectionTap('assistance')} to="assistance-section-container">
                  <img className="pro-icon" src={require('../img/icons/assistance-icon.png')} alt="assistance"></img>
                  Assistance
                </div>
                {/* Assistance Section */}
                <Element name="assistance-section-container" className={this.getAssistanceSectionClass()}>
                  {/* Discovery 1 */}
                  <Waypoint  onEnter={(obj) => this.handleWaypointEnter(1, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proAssistance1})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoAssistance01.png')} alt="logo"></img>
                    Google Go reads information aloud to support users with lower literacy
                    </div>
                  </div>

                  {/* Assistance 2 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(2, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proAssistance2})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoAssistance02.svg')} alt="logo"></img>
                    Jiny tells users how to interact with the UI through a virtual guide
                    </div>
                  </div>
        
                  {/* Assistance 3 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(3, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proAssistance3})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoAssistance03.png')} alt="logo"></img>
                    Google Go autocompletes each query to help users overcome typing obstacles
                    </div>
                  </div>

                  {/* Assistance 4 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(4, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proAssistance4})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoAssistance04.png')} alt="logo"></img>
                    Android Camera offers contextual assistance to help users be most successful within their product
                    </div>
                  </div>

                </Element>


                <div className="pro-nav-item show-if-mobile" onClick={() => this.mobileSectionTap('language')}>
                  <img className="pro-icon" src={require('../img/icons/language-icon.png')} alt="language"></img>
                  Language
                </div>
                {/* Language Section */}
                <Element name="language-section-container" className={this.getLanguageSectionClass()}>
                  {/* Language 1 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(1, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proLanguage1})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoLanguage01.png')} alt="logo"></img>
                    Grab shows two languages simultaneously to support multilingual users
                    </div>
                  </div>

                  {/* Language 2 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(2, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proLanguage3})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoLanguage03.png')} alt="logo"></img>
                    Google News helps users incorporate content written in various languages into their news stream
                    </div>
                  </div>


                  {/* Language 3 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(3, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proLanguage6})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoLanguage06.png')} alt="logo"></img>
                    KhataBook lets people watch the same content in different languages
                    </div>
                  </div>

                  {/* Language 2 */}
                  {/* <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(2, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proLanguage2})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo tiktok" src={require('../img/tools/products/2x/SpotlightLogoLanguage02.png')} alt="logo"></img>
                    TikTok allows users to select multiple language settings at one time
                    </div>
                  </div> */}
        


                  {/* Language 4 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(4, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proLanguage4})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoLanguage04.png')} alt="logo"></img>
                    Sharechat decouples the product language settings from the device language settings
                    </div>
                  </div>

                  {/* Language 5 */}
                  {/* <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(5, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proLanguage5})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo tiktok" src={require('../img/tools/products/2x/SpotlightLogoLanguage05.png')} alt="logo"></img>
                    TikTok separates the product language setting from the content being shown within the product
                    </div>
                  </div> */}
                </Element>


                <div className="pro-nav-item show-if-mobile" onClick={() => this.mobileSectionTap('voice')}>
                  <img className="pro-icon" src={require('../img/icons/voice-icon.png')} alt="voice"></img>
                  Voice
                </div>

                {/* Voice Section */}
                <Element name="voice-section-container" className={this.getVoiceSectionClass()}>
                  {/* Voice 1 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(1, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proVoice1})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoVoice01.png')} alt="logo"></img>
                    YouTube suggests queries that help users discover available voice commands
                    </div>
                  </div>

                  {/* Voice 2 */}
                  {/* <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(2, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proVoice2})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoVoice02.png')} alt="logo"></img>
                    Whatsapp makes voice interaction discoverable and accessible by exposing the mic icon at all times
                    </div>
                  </div> */}
        
                  {/* Voice 3 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(2, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proVoice3})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoVoice03.png')} alt="logo"></img>
                    Niki simplifies complex interactions through voice guidance and voice commands
                    </div>
                  </div>

                  {/* Voice 4 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(3, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proVoice4})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoVoice04.png')} alt="logo"></img>
                    Bolo encourages kids to read aloud and gives them instant feedback, even when completely offline 
                    </div>
                  </div>

                  {/* Voice 5 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(4, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proVoice5})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoVoice05.png')} alt="logo"></img>
                    Amap suggests different things to say based on where the user is within the flow
                    </div>
                  </div>
                </Element>


                <div className="pro-nav-item show-if-mobile" to="assistance-section-container" onClick={() => this.mobileSectionTap('onboarding')}>
                  <img className="pro-icon" src={require('../img/icons/onboarding-icon.png')} alt="onboarding"></img>
                  Onboarding
                </div>

                {/* Onboarding Section */}
                <Element name="onboarding-section-container"  className={this.getOnboardingSectionClass()}>

                  {/* Onboarding 1 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(1, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proOnboarding5})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoOnboarding05.png')} alt="logo"></img>
                    Google Go onboards users through short, clear videos
                    </div>
                  </div>

                  {/* Onboarding 2 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(2, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proOnboarding2})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo tiktok" src={require('../img/tools/products/2x/SpotlightLogoOnboarding02.png')} alt="logo"></img>
                    TikTok launches first-time users directly into the app’s core feature to onboard them contextually
                    </div>
                  </div>
        
                  {/* Onboarding 3 */}
                  {/* <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(3, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proOnboarding3})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoOnboarding03.png')} alt="logo"></img>
                    UC Browser uses animated icons to draw people’s attention to features they haven’t tried yet
                    </div>
                  </div> */}

                  {/* Onboarding 3 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(3, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proOnboarding4})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoOnboarding04.png')} alt="logo"></img>
                    Amap teaches users a series of interactions as they use the app, allowing them to learn by doing
                    </div>
                  </div>

                  {/* Onboarding 4 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(4, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proOnboarding1})`}}>
                    <div className="pro-content-text">
                      <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoOnboarding01.png')} alt="logo"></img>
                      Helo utilizes media-rich tooltips to help educate and onboard users with lower literacy
                    </div>
                  </div>
                </Element>


                <div className="pro-nav-item show-if-mobile" onClick={() => this.mobileSectionTap('navigation')}>
                  <img className="pro-icon" src={require('../img/icons/navigation.png')} alt="navigation"></img>
                  Navigation
                </div>


                {/* Navigation Section */}
                <Element name="navigation-section-container" className={this.getNavigationSectionClass()}>
                  {/* Navigation 1 */}
                  {/* <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(1, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proNavigation1})`}}>
                    <div className="pro-content-text">
                      <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoNavigation01.png')} alt="logo"></img>
                      Boomplay uses graphic cues to teach users how to navigate their app
                    </div>
                  </div> */}


                  {/* Navigation 1 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(1, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proNavigation2})`}}>
                    <div className="pro-content-text">
                      <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoNavigation02.png')} alt="logo"></img>
                    Google Play Store adapts its navigation to recommend similar content after the user has expressed interest
                    </div>
                  </div>
        
                  {/* Navigation 2 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(2, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proNavigation3})`}}>
                    <div className="pro-content-text">
                      <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoNavigation03.png')} alt="logo"></img>
                      bKash simplifies the cognitive load of navigation by applying a single, consistent color to each category of the app
                    </div>
                  </div>

                  {/* Navigation 4 */}
                  {/* <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(4, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proNavigation4})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoNavigation04.png')} alt="logo"></img>
                    UC Browser intensifies UI cues or guidance if users don’t appear to learn an interaction the first time it’s demonstrated
                    </div>
                  </div> */}

                  {/* Navigation 3 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(3, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proNavigation5})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoNavigation05.png')} alt="logo"></img>
                    Google Assistant allows people to request and navigate to features by voice, instead of finding them via the UI
                    </div>
                  </div>    


                  {/* Navigation 4 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(4, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proAssistance5})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoAssistance05.png')} alt="logo"></img>
                    Files lets users complete a traditionally multi-step process in one click
                    </div>
                  </div> 
                </Element>

                <div className="pro-nav-item show-if-mobile" onClick={() => this.mobileSectionTap('accounts')}>
                  <img className="pro-icon" src={require('../img/icons/accounts.png')} alt="accounts"></img>
                  Accounts
                </div>


                {/* Accounts Section */}
                <Element name="accounts-section-container" className={this.getAccountsSectionClass()}>


                  {/* Accounts 1 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(1, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proAccounts5})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo kormo" src={require('../img/tools/products/2x/kormo_24dp.svg')} alt="logo"></img>
                    Kormo's sign-up process is as simple as two contextual questions: location and job type
                    </div>
                  </div>

                  {/* Accounts 2 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(2, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proLanguage2})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo tiktok" src={require('../img/tools/products/2x/SpotlightLogoLanguage02.png')} alt="logo"></img>
                      TikTok allows users to customize settings
                    </div>
                  </div>


                  {/* Accounts 3 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(3, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proAccounts1})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoAccounts01.png')} alt="logo"></img>
                    Taobao offers family accounts to make the transactions within families easier
                    </div>
                  </div>

                  {/* Accounts 4 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(4, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proAccountsKhata})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoLanguage06.png')} alt="logo"></img>
                      Khatabook motivates users to create an account by touting other users similar to them
                    </div>
                  </div>

                  {/* Accounts 2 */}
                  {/* <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(2, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proAccounts2})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoAccounts02.png')} alt="logo"></img>
                    Xiami Music provides a special account mode for toddlers
                    </div>
                  </div> */}
        
                  {/* Accounts 3 */}
                  {/* <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(3, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proAccounts3})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoAccounts03.png')} alt="logo"></img>
                    Weibo allows people to try the product before requiring them to make an account
                    </div>
                  </div> */}

                  {/* Accounts 4 */}
                  {/* <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(4, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proAccounts4})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoAccounts04.png')} alt="logo"></img>
                    Likee pre-populates account information to reduce friction during sign-up
                    </div>
                  </div> */}

                </Element>


                <div className="pro-nav-item show-if-mobile" onClick={() => this.mobileSectionTap('discovery')}>
                  <img className="pro-icon" src={require('../img/icons/discovery.png')} alt="discovery"></img>
                  Discovery
                </div>

                {/* Discovery Section */}
                <Element name="discovery-section-container" className={this.getDiscoverySectionClass()}>
                  {/* Discovery 1 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(1, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proDiscovery1})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoDiscovery01.png')} alt="logo"></img>
                    Naver uses graphic cues to show users how to swipe to discover things that are currently hidden off-screen
                    </div>
                  </div>

                  {/* Discovery 2 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(2, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proDiscovery2})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoDiscovery02.png')} alt="logo"></img>
                    LIC Superagent shows all the features upfront to help users see the variety of features available to them
                    </div>
                  </div>
        
                  {/* Discovery 3 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(3, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proDiscovery3})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoDiscovery03.png')} alt="logo"></img>
                    Google Assistant helps users find new use-cases by suggesting phrases they might want to say
                    </div>
                  </div>

                  {/* Discovery 4 */}
                  {/* <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(4, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proDiscovery4})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoDiscovery04.png')} alt="logo"></img>
                    Weibo starts the experience with both content and search queries to help people get started with something relevant to them
                    </div>
                  </div> */}

                  {/* Discovery 4 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(4, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proDiscovery5})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoDiscovery05.png')} alt="logo"></img>
                    Duolingo unlocks new lessons only as the user progresses through the curriculum
                    </div>
                  </div>
                </Element>


                <div className="pro-nav-item show-if-mobile" onClick={() => this.mobileSectionTap('visuals')}>
                  <img className="pro-icon" src={require('../img/icons/visuals-icon.png')} alt="visuals"></img>
                  Visuals
                </div>

                {/* Visuals Section */}
                <Element name="visuals-section-container" className={this.getVisualsSectionClass()}>
                  {/* Visuals 1 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(1, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proVisuals1})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoVisuals01.png')} alt="logo"></img>
                    Flipkart celebrates the user’s appreciation of higher density UI
                    </div>
                  </div>

                  {/* Visuals 2 */}
                  {/* <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(2, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proVisuals2})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoVisuals02.png')} alt="logo"></img>
                    WeChat presents culturally relevant moments of delight
                    </div>
                  </div> */}
        
                  {/* Visuals 2 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(2, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proVisuals3})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoVisuals03.png')} alt="logo"></img>
                    Files uses animation to explain abstract concepts visually
                    </div>
                  </div>

                  {/* Visuals 3 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(3, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proVisuals4})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoVisuals04.png')} alt="logo"></img>
                    Google Pay celebrates local holidays to build emotional connection with users
                    </div>
                  </div>

                  {/* Visuals 4 */}
                  {/* <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(4, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proVisuals5})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoVisuals05.png')} alt="logo"></img>
                    Files uses neutral and inclusive mascots to show human human interactions and emotions
                    </div>
                  </div> */}

                  {/* Visuals 4 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(4, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proVisuals6})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoVisuals06.png')} alt="logo"></img>
                      Gojek localizes visuals to mirror familiar behaviors and experiences
                    </div>
                  </div>
                </Element>





                <div className="pro-nav-item show-if-mobile" onClick={() => this.mobileSectionTap('transactions')}>
                  <img className="pro-icon" src={require('../img/icons/transactions-icon.png')} alt="transactions"></img>
                  Transactions
                </div>

                {/* Transactions Section */}
                <Element name="transactions-section-container" className={this.getTransactionsSectionClass()}>
                  {/* Transactions 1 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(1, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proTransactions1})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoTransactions01.png')} alt="logo"></img>
                    Flipkart offers multiple payment options, including ones most popular among local users
                    </div>
                  </div>

                  {/* Transactions 2 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(2, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proTransactions2})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoTransactions02.png')} alt="logo"></img>
                    bKash uses tap-and-hold to reduce mistakes during high stakes transactions
                    </div>
                  </div>
        
                  {/* Transactions 3 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(3, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proTransactions3})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoTransactions03.png')} alt="logo"></img>
                    Google Pay makes payments social with quick access to contacts
                    </div>
                  </div>

                  {/* Transactions 4 */}
                  {/* <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(4, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proTransactions4})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoTransactions04.png')} alt="logo"></img>
                      Albert shows how much of a budget is safe to spend by subtracting bills and expenses
                    </div>
                  </div> */}

                  {/* Transactions 4 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(4, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proTransactions5})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoTransactions05.png')} alt="logo"></img>
                    Paytm allows pay-by-scanning to reduce friction during transactions
                    </div>
                  </div>
                </Element>


                <div className="pro-nav-item show-if-mobile" onClick={() => this.mobileSectionTap('data')}>
                  <img className="pro-icon" src={require('../img/icons/data-icon.png')} alt="data"></img>
                  Data
                </div>

                {/* Data Section */}
                <Element name="data-section-container" className={this.getDataSectionClass()}>
                  {/* Data 1 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(1, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proData1})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoData01.png')} alt="logo"></img>
                    Instagram automatically downloads content while online to prepare for offline usage
                    </div>
                  </div>

                  {/* Data 2 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(2, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proData2})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoData02.png')} alt="logo"></img>
                    YouTube provides transparency and control around data consumption
                    </div>
                  </div>
        
                  {/* Data 3 */}
                  {/* <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(3, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proData3})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoData03.png')} alt="logo"></img>
                    Weibo adapts image quality based on internet connectivity
                    </div>
                  </div> */}

                  {/* Data 3 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(3, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proData4})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoData04.png')} alt="logo"></img>
                    YouTube Go previews what to expect while users are choosing to save or play the video
                    </div>
                  </div>

                  {/* Data 4 */}
                  <Waypoint bottomOffset={this.bottomOffsetValue} onEnter={(obj) => this.handleWaypointEnter(4, obj)}></Waypoint>
                  <div className="pro-content-container text-3" style={{backgroundImage: `url(${proData5})`}}>
                    <div className="pro-content-text">
                    <img className="pro-logo" src={require('../img/tools/products/2x/SpotlightLogoData05.png')} alt="logo"></img>
                    Youtube Go helps you share videos to friends nearby, without using up any data
                    </div>
                  </div>
                </Element>

              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="pro-nav-top-buttons-container  pro-nav-top-buttons-bottom show-if-mobile">
                <LinkDom to="/tools/provocations" className="pro-nav-top-button text-6 bold-text">
                  Provocations
                </LinkDom>
                <div className="pro-nav-top-button selected text-6 bold-text">Products</div>
              </div>
            </Col>
          </Row>
        </Container>


        <Footer hideTopFooterSection={true} />


      </div>
    );
  }
}

export default withRouter(Products);
