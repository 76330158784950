import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { motion } from "framer-motion"
import { withRouter } from "react-router-dom";


class SprintLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentHover: ""
    }
  }

  menuColors = {
    prepare: "#6452F6",
    define: "#FF9675",
    ideate: "#FFCE22",
    design: "#35B9A1",
    test: "#39495C"
  }

  menuVariants = {
    hovered: () => ({
      // width: "460px",
      // height: "180px",
      // color: "white",
      // backgroundColor: "orange",
      // transition: {
      //   delayChildren: 0.2
      // }
    }),
    notHovered: () => ({
      // width: "300px",
      // height: "92px",
      // color: "#39495C",
      // backgroundColor: 'white'
    })
  };

  menuIconVariants = {
    hovered: {
      top: '5%',
      right: '5%',
      transition: { 
        type: "spring",
        stiffness: 260,
        damping: 20
      }
    },
    notHovered: {
      top: '25%',
      right: '15%'
    }
  };

  menuImageVariants = {
    hovered: {
      rotate: 20
    },
    notHovered: {
      rotate: 0,
    }
  };


  elementHovered = (section) => {
    this.setState({currentHover: section});
  }

  elementLeft = () => {
    this.setState({currentHover: ""});
  }

  render() {
    return (
      <div className="normal-section">
      <Container>
        <Row>
          <Col className="text-center" lg={{ span: 4, offset: 4 }}>
            <div className="text-2 margin-bottom-me">Tools to design for digital confidence</div>
          </Col>
        </Row>
        <Row className="margin-bottom-me">
          <Col className="text-center grid-container" md={{ span: 12, offset: 0 }}>

            {/* <div className="ethan-test test1"></div>
            <div className="ethan-test test2"></div>
            <div className="ethan-test test3"></div>
            <div className="ethan-test test4"></div> */}

            <motion.div
              animate={this.state.currentHover === 'sprint-canvas' ? "hovered" : "notHovered"}
              onMouseEnter={() => {this.elementHovered('sprint-canvas')}}
              onMouseLeave={this.elementLeft}
              className="tools-tools-container" 
              onClick={() => {this.props.history.push('/tools/sprint-canvas')}}>
              <motion.img variants={this.menuIconVariants} className="hover-image" src={require('../img/icons/black-forward-slash.png')} alt="tools"></motion.img>
              <motion.img variants={this.menuImageVariants} className="tools-tool-image text-title-margin-bottom" src={require('../img/tools/sprint-canvas.png')} alt="Spring Canvas"></motion.img>
              <div className="text-7 text-title-margin-bottom-small">
                The Sprint Canvas
                <img className="tools-tool-arrow" src={require('../img/arrow-right-small-black.png')} alt="Go"></img>
              </div>
              <div className="text-35 regular">How should we structure our process?</div>
            </motion.div>

            <motion.div 
              animate={this.state.currentHover === 'sprint-focus-cards' ? "hovered" : "notHovered"}
              onMouseEnter={() => {this.elementHovered('sprint-focus-cards')}}
              onMouseLeave={this.elementLeft}
              className="tools-tools-container sprint-focus-cards" 
              onClick={() => {this.props.history.push('/tools/sprint-focus-cards')}}>
              <motion.img variants={this.menuIconVariants} className="hover-image" src={require('../img/icons/purple-diagonal-line.png')} alt="tools"></motion.img>
              <motion.img variants={this.menuImageVariants} className="tools-tool-image text-title-margin-bottom" src={require('../img/tools/sprint-focus-cards-smaller@2x.png')} alt="Spring Canvas"></motion.img>
              <div className="text-7 text-title-margin-bottom-small">
                Sprint Focus Cards
                <img className="tools-tool-arrow" src={require('../img/arrow-right-small-black.png')} alt="Go"></img>
              </div>
              <div className="text-35 regular">What challenge should we focus on?</div>
            </motion.div>

            <motion.div
              animate={this.state.currentHover === 'user-context-cards' ? "hovered" : "notHovered"}
              onMouseEnter={() => {this.elementHovered('user-context-cards')}}
              onMouseLeave={this.elementLeft}
              className="tools-tools-container user-context-cards" 
              onClick={() => {this.props.history.push('/tools/user-context-cards')}}>
              <motion.img variants={this.menuIconVariants} className="hover-image second-position" src={require('../img/icons/orange-circle.png')} alt="tools"></motion.img>
              <motion.img variants={this.menuImageVariants} className="tools-tool-image user-context-cards-image text-title-margin-bottom" src={require('../img/tools/user-context-cards-smaller@2x.png')} alt="Spring Canvas"></motion.img>
              <div className="text-7 text-title-margin-bottom-small">
              User Context Cards
                <img className="tools-tool-arrow" src={require('../img/arrow-right-small-black.png')} alt="Go"></img>
              </div>
              <div className="text-35 regular">How are our users’ contexts different from or similar to our own?</div>
            </motion.div>

            <motion.div
              animate={this.state.currentHover === 'design-principle-cards' ? "hovered" : "notHovered"}
              onMouseEnter={() => {this.elementHovered('design-principle-cards')}}
              onMouseLeave={this.elementLeft}
              className="tools-tools-container" 
              onClick={() => {this.props.history.push('/tools/design-principle-cards')}}>
              <motion.img variants={this.menuIconVariants} className="hover-image" src={require('../img/icons/yellow-rectangle.png')} alt="tools"></motion.img>
              <motion.img variants={this.menuImageVariants} className="tools-tool-image design-principle-cards text-title-margin-bottom" src={require('../img/tools/design-principle-cards-smaller@2x.png')} alt="Spring Canvas"></motion.img>
              <div className="text-7 text-title-margin-bottom-small">
                Design Principle Cards
                <img className="tools-tool-arrow" src={require('../img/arrow-right-small-black.png')} alt="Go"></img>
              </div>
              <div className="text-35 regular">Which principles should guide our decisions?</div>
            </motion.div>

            <motion.div
              animate={this.state.currentHover === 'inspiration-tool' ? "hovered" : "notHovered"}
              onMouseEnter={() => {this.elementHovered('inspiration-tool')}}
              onMouseLeave={this.elementLeft}
              className="tools-tools-container inspiration-tool" 
              onClick={() => {this.props.history.push('/tools/inspiration-tool')}}>
              <motion.img variants={this.menuIconVariants} className="hover-image" src={require('../img/icons/green-triangle.png')} alt="tools"></motion.img>
              <motion.img variants={this.menuImageVariants} className="tools-tool-image text-title-margin-bottom" src={require('../img/tools/inspiration-tool-smaller@2x.png')} alt="Spring Canvas"></motion.img>
              <div className="text-7 text-title-margin-bottom-small">
                Inspiration Tool
                <img className="tools-tool-arrow" src={require('../img/arrow-right-small-black.png')} alt="Go"></img>
              </div>
              <div className="text-35 regular">Where can we find inspiration?</div>
            </motion.div>

            <motion.div
              animate={this.state.currentHover === 'test-frameworks' ? "hovered" : "notHovered"}
              onMouseEnter={() => {this.elementHovered('test-frameworks')}}
              onMouseLeave={this.elementLeft}
              className="tools-tools-container" 
              onClick={() => {this.props.history.push('/tools/test-framework')}}>
              <motion.img variants={this.menuIconVariants} className="hover-image" src={require('../img/icons/black-diagonal-line.png')} alt="tools"></motion.img>
              <motion.img variants={this.menuImageVariants} className="tools-tool-image text-title-margin-bottom" src={require('../img/tools/test-framework-smaller@2x.png')} alt="Spring Canvas"></motion.img>
              <div className="text-7 text-title-margin-bottom-small">
                The Test Framework
                <img className="tools-tool-arrow" src={require('../img/arrow-right-small-black.png')} alt="Go"></img>
              </div>
              <div className="text-35 regular">How can we measure if we’re making a difference for our users?</div>
            </motion.div>
          </Col>
        </Row>          

      </Container>
    </div>

    );
  }
}

export default withRouter(SprintLinks);
