import React, { Component } from 'react';
import './Tools.scss';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import TopNav from '../TopNav/TopNav'
import DownloadButton from '../DownloadButton'
import Footer from '../Footer/Footer'
import { withRouter } from "react-router-dom";

class UserContextCards extends Component {
  componentDidMount() {
    // console.log(this.props.currentTopNav);
  }

  moreToolsClick = (whichTool) => {
    console.log(whichTool)
  }  

  updateAppEmail = (address) => {
    this.props.onEmailChange(address);
  }

  render() {
    return (
      <div className="user-context-cards">
        <TopNav currentTopNav="tools" />

        <Container className="page-top-space">
          <Row>
            <Col className="text-left" lg={{span: 6, offset: 1}}>
              <div className="text-5 bold-text">User Context Cards</div>
              <div className="text-1 focus-area-title margin-bottom-me">How are our users’ contexts different from or similar to our own?</div>
              <div className="margin-bottom-me less-wide-text">
                Use this deck of 10 cards to help your team articulate who you’re designing for by understanding the unique context of the next wave of internet users. Incorporate the cards into your process to keep empathy at the forefront.
              </div>
              <DownloadButton onEmailChange={this.updateAppEmail} emailFromApp={this.props.emailFromApp} pdf="user-context-cards" text="Download and Print" />
              <div className="creative-commons">* This work is licensed under a Creative Commons Attribution 4.0 International License</div>
            </Col>
            <Col className="relative tools-subpage-hero-container" lg={{span: 5}}>
              <img className="tools-subpage-hero" src={require('../img/tools/empathy-cards@2x.png')} alt="Empathy Cards"></img>
            </Col>
          </Row>
        </Container>

        <div className="tools-subpage-bottom-section">
        <Container>
          <Row>
            <Col className="text-left" lg={{span: 11, offset: 1}}>
              <div className="text-2 margin-bottom-me">More Tools</div>

              <div onClick={() => {this.props.history.push('/tools/sprint-canvas')}} className="more-tools-row">
                <div className="more-tools-column icon-containing-col">
                  <img className="tools-subpage-icon" src={require('../img/icons/black-forward-slash.png')} alt="tools"></img>
                </div>
                <div className="more-tools-column text-col">
                   <div className="text-5 bold-text">How should we structure our sprint?</div>
                  <div className="arrow-link-row">
                    <div className="arrow-link-col text-6">The Sprint Canvas</div>
                    <div className="arrow-link-col">
                      <img className="tools-tool-arrow" src={require('../img/arrow-right-small-black.png')} alt="Go"></img>
                    </div>
                  </div>
                </div>
              </div>

              <div onClick={() => {this.props.history.push('/tools/sprint-focus-cards')}} className="more-tools-row">
                <div className="more-tools-column icon-containing-col">
                  <img className="tools-subpage-icon" src={require('../img/icons/purple-diagonal-line.png')} alt="tools"></img>
                </div>
                <div className="more-tools-column text-col">
                   <div className="text-5 bold-text">What challenge should we focus on?</div>                  
                  <div className="arrow-link-row">
                    <div className="arrow-link-col text-6">Sprint Focus Cards</div>
                    <div className="arrow-link-col">
                      <img className="tools-tool-arrow" src={require('../img/arrow-right-small-black.png')} alt="Go"></img>
                    </div>
                  </div>
                </div>
              </div>

              <div onClick={() => {this.props.history.push('/tools/design-principle-cards')}} className="more-tools-row">
                <div className="more-tools-column icon-containing-col">
                  <img className="tools-subpage-icon" src={require('../img/icons/yellow-rectangle.png')} alt="tools"></img>
                </div>
                <div className="more-tools-column text-col">
                   <div className="text-5 bold-text">Which principles should guide our decisions?</div>                  
                  <div className="arrow-link-row">
                    <div className="arrow-link-col text-6">Design Principle Cards</div>
                    <div className="arrow-link-col">
                      <img className="tools-tool-arrow" src={require('../img/arrow-right-small-black.png')} alt="Go"></img>
                    </div>
                  </div>
                </div>
              </div>

              <div onClick={() => {this.props.history.push('/tools/inspiration-tool')}} className="more-tools-row">
                <div className="more-tools-column icon-containing-col">
                  <img className="tools-subpage-icon" src={require('../img/icons/green-triangle.png')} alt="tools"></img>
                </div>
                <div className="more-tools-column text-col">
                   <div className="text-5 bold-text">Where can we find inspiration?</div>                  
                  <div className="arrow-link-row">
                    <div className="arrow-link-col text-6">Inspiration Tool</div>
                    <div className="arrow-link-col">
                      <img className="tools-tool-arrow" src={require('../img/arrow-right-small-black.png')} alt="Go"></img>
                    </div>
                  </div>
                </div>
              </div>

              <div onClick={() => {this.props.history.push('/tools/test-framework')}} className="more-tools-row">
                <div className="more-tools-column icon-containing-col">
                  <img className="tools-subpage-icon" src={require('../img/icons/black-diagonal-line.png')} alt="tools"></img>
                </div>
                <div className="more-tools-column text-col">
                   <div className="text-5 bold-text">How can we measure if we’re making a difference for our users?</div>                  
                  <div className="arrow-link-row">
                    <div className="arrow-link-col text-6">The Test Framework</div>
                    <div className="arrow-link-col">
                      <img className="tools-tool-arrow" src={require('../img/arrow-right-small-black.png')} alt="Go"></img>
                    </div>
                  </div>
                </div>
              </div>

            </Col>
          </Row>
        </Container>
        </div>
        <Footer hideTopFooterSection={true} />


      </div>
    );
  }
}

export default withRouter(UserContextCards);
