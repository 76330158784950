import React, { Component } from 'react';
import './Tools.scss';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import TopNav from '../TopNav/TopNav'
import Footer from '../Footer/Footer'
import { withRouter } from "react-router-dom";

class InspirationTool extends Component {

  componentDidMount() {
    // console.log(this.props.currentTopNav);
  }

  moreToolsClick = (whichTool) => {
    console.log(whichTool)
  }  

  render() {
    return (
      <div className="design-principle-cards">
        <TopNav currentTopNav="tools" />

        <Container className="page-top-space">
          <Row>
            <Col className="text-left" md={{span: 6, offset: 1}}>
              <div className="text-5 bold-text">Inspiration Tool</div>
              <div className="text-1 focus-area-title margin-bottom-me">Where can we find inspiration?</div>
              <div className="margin-bottom-me less-wide-text">Use this Inspiration Tool to help your team brainstorm and sketch new ideas. Browse provocations (illustrative UI to spark the imagination) and products (spotlights on other apps solving for relevant challenges) to fuel the creative process.</div>
            </Col>
          </Row>
          <Row>
            <Col className="relative" md={{span: 5, offset: 1}}>
              <img onClick={() => {this.props.history.push('/tools/provocations')}} className="inspiration-tools-main-image" src={require('../img/tools/show-me-provocations.png')} alt="show me provocations"></img>
            </Col>
            <Col className="relative" md={{span: 5}}>
              <img onClick={() => {this.props.history.push('/tools/products')}} className="inspiration-tools-main-image" src={require('../img/tools/show-me-real-products.png')} alt="show me provocations"></img>
            </Col>
          </Row>
        </Container>

        <div className="tools-subpage-bottom-section">
        <Container>
          <Row>
            <Col className="text-left" md={{span: 11, offset: 1}}>
              <div className="text-2 margin-bottom-me">More Tools</div>

              <div onClick={() => {this.props.history.push('/tools/sprint-canvas')}} className="more-tools-row">
                <div className="more-tools-column icon-containing-col">
                  <img className="tools-subpage-icon" src={require('../img/icons/black-forward-slash.png')} alt="tools"></img>
                </div>
                <div className="more-tools-column text-col">
                   <div className="text-5 bold-text">How should we structure our sprint?</div>
                  <div className="arrow-link-row">
                    <div className="arrow-link-col text-6">The Sprint Canvas</div>
                    <div className="arrow-link-col">
                      <img className="tools-tool-arrow" src={require('../img/arrow-right-small-black.png')} alt="Go"></img>
                    </div>
                  </div>
                </div>
              </div>

              <div onClick={() => {this.props.history.push('/tools/sprint-focus-cards')}} className="more-tools-row">
                <div className="more-tools-column icon-containing-col">
                  <img className="tools-subpage-icon" src={require('../img/icons/purple-diagonal-line.png')} alt="tools"></img>
                </div>
                <div className="more-tools-column text-col">
                  <div className="text-5 bold-text">What challenge should we focus on?</div>
                  <div className="arrow-link-row">
                    <div className="arrow-link-col text-6">Sprint Focus Cards</div>
                    <div className="arrow-link-col">
                      <img className="tools-tool-arrow" src={require('../img/arrow-right-small-black.png')} alt="Go"></img>
                    </div>
                  </div>
                </div>
              </div>


              <div onClick={() => {this.props.history.push('/tools/user-context-cards')}} className="more-tools-row">
                <div className="more-tools-column icon-containing-col">
                  <img className="tools-subpage-icon" src={require('../img/icons/orange-circle.png')} alt="tools"></img>
                </div>
                <div className="more-tools-column text-col">
                   <div className="text-5 bold-text">How are our users’ contexts different from or similar to our own?</div>                  
                  <div className="arrow-link-row">
                    <div className="arrow-link-col text-6">User Context Cards</div>
                    <div className="arrow-link-col">
                      <img className="tools-tool-arrow" src={require('../img/arrow-right-small-black.png')} alt="Go"></img>
                    </div>
                  </div>
                </div>
              </div>


              <div onClick={() => {this.props.history.push('/tools/design-principle-cards')}} className="more-tools-row">
                <div className="more-tools-column icon-containing-col">
                  <img className="tools-subpage-icon" src={require('../img/icons/yellow-rectangle.png')} alt="tools"></img>
                </div>
                <div className="more-tools-column text-col">
                  <div className="text-5 bold-text">Which principles should guide our decisions?</div>                  
                  <div className="arrow-link-row">
                    <div className="arrow-link-col text-6">Design Principle Cards</div>
                    <div className="arrow-link-col">
                      <img className="tools-tool-arrow" src={require('../img/arrow-right-small-black.png')} alt="Go"></img>
                    </div>
                  </div>
                </div>
              </div>

              <div onClick={() => {this.props.history.push('/tools/test-framework')}} className="more-tools-row">
                <div className="more-tools-column icon-containing-col">
                  <img className="tools-subpage-icon" src={require('../img/icons/black-diagonal-line.png')} alt="tools"></img>
                </div>
                <div className="more-tools-column text-col">
                   <div className="text-5 bold-text">How can we measure if we’re making a difference for our users?</div>                  
                  <div className="arrow-link-row">
                    <div className="arrow-link-col text-6">The Test Framework</div>
                    <div className="arrow-link-col">
                      <img className="tools-tool-arrow" src={require('../img/arrow-right-small-black.png')} alt="Go"></img>
                    </div>
                  </div>
                </div>
              </div>

            </Col>
          </Row>
        </Container>
        </div>
        <Footer hideTopFooterSection={true} />


      </div>
    );
  }
}

export default withRouter(InspirationTool);
