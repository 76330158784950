import React, { Component } from 'react';
import './Opportunities.scss';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import MediaQuery from 'react-responsive'
import TopNav from '../TopNav/TopNav'
import FocusAreas from '../FocusAreas/FocusAreas'
import FocusAreasMobile from '../FocusAreas/FocusAreasMobile'
import AudioClips from '../AudioClips/AudioClips'
import MoreResources from '../MoreResources/MoreResources'
import DigitalTools from '../DigitalTools/DigitalTools';
import Footer from '../Footer/Footer'
import oppsTopBack from '../img/opps-top-back2.png';
import oppBox1Back from '../img/opp-box-1-back.png';
import oppBox3Back from '../img/opp-box-3-back.png';

class Opportunities extends Component {


  render() { 
    return (
      <div className="opportunities">
        <TopNav currentTopNav="opportunities" />
        <div className="page-top" style={{backgroundImage: `url(${oppsTopBack})`}}>
          <Container className="opps-top">
            <Row>
              <Col className="text-left" md={{ span: 6, offset: 2 }} lg={{ span: 8, offset: 2 }}>
                <div className="text-1">The Next Wave of Internet Users</div>
              </Col>
            </Row>

            <Row className="opp-row">
              <Col className="text-right" xl={{ span: 7 }}>
                <div className="opp-box-1-container">
                  <div className="opp-box-1-shadow" style={{backgroundImage: `url(${oppBox1Back})`}}></div>
                  <div className="opp-box opp-box-1">
                    <div className="opp-title-1 text-5 regular"><span className="text-5 medium">Huria  |</span>  Kupang, Indonesia</div>
                    <div className="text-4">"If I click the wrong button, my money might go somewhere else."</div>
                  </div>
                </div>

                <div className="opp-box-2-container">
                  <img className="opp-box-2-squiggle" src={require('../img/opp-box-2-squiggle.png')} alt="squiggle"></img>
                  {/* <div className="opp-box-shadow" style={{backgroundImage: `url(${oppBox2Back})`}}></div> */}
                  <div className="opp-box opp-box-2">
                    <div className="opp-title-1 text-5 regular"><span className="text-5 medium">Mamta  |</span>  Jaipur, India</div>
                    <div className="text-4 text-title-margin-bottom">“Talking is easy,<br /> typing is hard.”</div>
                    <div className="text-6 opp-small-text">
                      <div>Mamta just got her first smartphone, and she loves watching videos on YouTube to discover recipes, learn stitching techniques, and listen to her favorite Bollywood songs.</div>
                      <br />
                      <div>Her phone, a Samsung J2 with a cracked screen, was passed down to her by a family member. Typing is difficult, so she finds it easier to use voice when looking for videos. It doesn't always go as intended though. When using her phone outside in a loud environment and it simply doesn’t understand her. She blames herself when it doesn’t work.</div>
                    </div>
                  </div>
                  <img className="opp-box-2-human" src={require('../img/opp-box-2-human.png')} alt="Opportunites"></img>
                </div>
                <div className="opp-box-2-human-mobile-container">
                  <img className="opp-box-2-human-mobile" src={require('../img/opp-box-2-human.png')} alt="Opportunites"></img>
                </div>
              </Col>
              <Col className="text-left remove-left-padding" xl={{ span: 4 }}>
                <div className="opp-box-3-container">
                  <img className="opp-box-3-sun-mobile" src={require('../img/opp-box-3-sun.png')} alt="sun"></img>
                  <img className="opp-box-3-human" src={require('../img/opp-box-3-human.png')} alt="Opportunites"></img>
                  <img className="opp-box-3-human-mobile" src={require('../img/opp-box-3-human-mobile.png')} alt="Opportunites"></img>
                  {/* <div className="opp-box-3-shadow" style={{backgroundImage: `url(${oppBox3Back})`}}></div> */}
                  <img src={require('../img/opp-box-3-back.png')} alt="shadow" className="opp-box-3-shadow"></img>
                  <div className="opp-box opp-box-3">
                    <div className="opp-title-1 text-5 regular"><span className="text-5 medium">Elijah  |</span>  Kampala, Uganda</div>
                    <div className="text-4 text-title-margin-bottom">"Being able to send customers photos of my projects has transformed my business."</div>
                    <div className="text-6 opp-small-text">
                    Elijah is a metalworker who builds fences, bedposts, and furniture. He uses WhatsApp to close deals with customers and share progress as he works on a project. 
                      <br /><br />
                      Despite Elijah's low English literacy, he keeps it as the primary language on his phone because according to him, "technology is supposed to be in English." This makes it nearly impossible for him to read the words on the screen. He’s able to run his business by memorizing where all of the important WhatsApp features are and texting photographs back and forth with his customers.
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={{ span: 6 }}>
              </Col>
            </Row>
          </Container>
        </div>

        <MediaQuery minDeviceWidth={992}>
          <FocusAreas />
        </MediaQuery>
        <MediaQuery maxDeviceWidth={991}>
          <FocusAreasMobile />
        </MediaQuery>


        <AudioClips />
        <MoreResources />
        <DigitalTools page="opportunities" />
        <Footer />
      </div>
    );
  }
}


export default Opportunities;
