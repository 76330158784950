// src/firebase.js
import firebase from 'firebase/app';
import '@firebase/firestore'
const config = {
    apiKey: "AIzaSyBpKVM__FIHvwXEPWfORJLH_dcGw0AGKzE",
    authDomain: "last-mile-money.firebaseapp.com",
    databaseURL: "https://last-mile-money.firebaseio.com",
    projectId: "last-mile-money",
    storageBucket: "last-mile-money.appspot.com",
    messagingSenderId: "553816829222",
    appId: "1:553816829222:web:d3c74c56326296493de5e7",
    measurementId: "G-PF34TGB0Y2"
};
firebase.initializeApp(config);
export default firebase;