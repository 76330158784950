import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import BoxCards from '../ParallaxImages/BoxCards';
import { Link } from "react-router-dom";

function DigitalTools(props) {
  return (
    <div className="normal-section digital-tools">
      <Container>
        <Row>
          <Col className="text-center" lg={{ span: 6, offset: 3 }} xs={{ span: 10, offset: 1 }}>
            <div className="text-2 text-title-margin-bottom">The Digital Confidence<br /> Design Tools</div>
            <div className="">The tools – developed by IDEO, Google, and the Bill & Melinda Gates Foundation – help teams design digital services for the next wave of internet users. Use them to see your app with fresh eyes, design new flows, develop innovative interaction patterns, or even brainstorm a brand-new product.</div>
          
            <Link to="/tools" className="button1 explore-tools-button">Explore the Tools</Link>
          </Col>
        </Row>
        <Row>
          <Col className="text-center" xs={{ span: 10, offset: 1 }}>

            <Link to="/tools" className="sprint-box-container">
              <img className="sprint-box-back sprint-box-image" src={require('../img/parallax/box-back.png')} alt="Sprint Box"></img>
              
              {props.page
                ? <BoxCards page="opportunities" />
                : <BoxCards />
              }

              <img className="sprint-box-front sprint-box-image" src={require('../img/parallax/box-front.png')} alt="Sprint Box"></img>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default DigitalTools;
