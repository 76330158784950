import React, { Component } from 'react';
import './TopNav.scss';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {
  Link
} from "react-router-dom";


class TopNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileNavOpen: false
    }
  }

  componentDidMount() {
    // console.log(this.props.currentTopNav);
  }

  getNavItemClass = (whichNav) => {
    if (this.props.currentTopNav === "opportunities" && whichNav === "opportunities") {
      return "top-nav-item on";
    } else if (this.props.currentTopNav === "tools" && whichNav === "tools") {
      return "top-nav-item on";
    } else {
      return "top-nav-item";
    }
  }

  getTopNavClass = () => {
    if (this.props.currentTopNav === "home") {
      return "top-nav home";
    } else {
      return "top-nav";
    }   
  }

  getTopNavMobileClass = () => {
    if (this.props.currentTopNav === "home") {
      return "top-nav-mobile home";
    } else {
      return "top-nav-mobile not-home";
    }   
  }

  openMobilNav = () => {
    this.setState({mobileNavOpen: true});
  }

  closeMobilNav = () => {
    this.setState({mobileNavOpen: false});
  }

  getMobileNavClass = () => {
    if (this.state.mobileNavOpen) {
      return "mobile-nav open"
    } else {
      return "mobile-nav"
    }
  }

  render() {
    return (
      <div>
        <div className={this.getTopNavClass()}>
          <Container>
            <Row>
              <Col md={{ span: 6 }}>
                <div className="top-nav-item home-link">
                  <Link className="home-link" to="/">Designing for Digital Confidence</Link>
                </div>
              </Col>
              <Col md={{ span: 6 }} className="text-right">
                <div className={this.getNavItemClass('opportunities')}>
                  <Link className="top-nav-link" to="/opportunities">Opportunities &amp; Obstacles</Link>
                </div>
                <div className={this.getNavItemClass('tools')}>
                  <Link className="top-nav-link" to="/tools">The Tools</Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className={this.getTopNavMobileClass()}>
          <a href="/" className="mobile-nav-title">Designing for<br />Digital Confidence</a>
          <img onClick={this.openMobilNav} className="home-burger" src={require('../img/icons/burger-white@2x.png')} alt="Open"></img>
          <div className={this.getMobileNavClass()}>
            <img onClick={this.closeMobilNav} className="home-close-burger" src={require('../img/icons/burger-close-white@2x.png')} alt="Close"></img>
          
            <a href="/opportunities" className="mobile-nav-section mobile-nav-top">
              <div className="text-3">Opportunities <br />&amp; Obstacles</div>
              <img className="mobile-nav-arrow" src={require('../img/mobile-nav-right-arrow.png')} alt="Go"></img>
              <img className="mobile-nav-image" src={require('../img/mobile-nav-top-image.png')} alt="Go"></img>
            </a>
            <a href="/tools" className="mobile-nav-section mobile-nav-bottom">
              <div className="text-3">The Tools</div>
              <img className="mobile-nav-arrow" src={require('../img/mobile-nav-right-arrow.png')} alt="Go"></img>
              <img className="mobile-nav-image bottom" src={require('../img/mobile-nav-bottom-image.png')} alt="Go"></img>
            </a>
          
          
          </div>
        </div>
      </div>
    );
  }
}

export default TopNav;
