import React, { Component } from 'react';
import './Home.scss';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import VideoModalDialog from '../ModalDialogVideo'

class HomeVideo extends Component {
  componentDidMount() {
    // console.log(this.props.currentTopNav);
  }

  render() {
    return (
      <div className="grey-section home-video-container">
        <Container className="focus-areas-container">
          <Row className="">
            <Col className="text-center relative" xl={{ span: 6, offset: 3 }} xs={{ span: 10, offset: 1 }}>
              <img className="home-video-squiggle" src={require('../img/home-video-back.png')} alt="squiggle"></img>
              <div className="text-5 our-mission-title text-title-margin-bottom">Our Mission</div>
              <div className="text-3">Catalyze a community of product makers to build digital services that work for everyone. Regardless of where they live, what language they speak, or which <span className="orphan-word">device they use.</span></div>
              <VideoModalDialog />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default HomeVideo;
