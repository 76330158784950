import React, { Component } from 'react';
import './SprintTools.scss';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { motion } from "framer-motion"
import {withRouter} from 'react-router-dom'


class MobileSprintTools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSection: "prepare",
      prepareOpen: true,
      defineOpen: false,
      ideateOpen: false,
      designOpen: false,
      testOpen: false,
    }
  }

  menuColors = {
    prepare: "#6452F6",
    define: "#FF9675",
    ideate: "#FFCE22",
    design: "#35B9A1",
    test: "#39495C"
  }

  menuVariants = {
    expanded: () => ({
      width: "100%",
      height: "auto",
      color: "white",
      zIndex: 30,
      backgroundColor: this.menuColors[this.state.currentSection],
      transition: {
        delayChildren: 0.2
      }
    }),
    collapsed: () => ({
      zIndex: 20,
      width: "100%",
      height: "92px",
      color: "#39495C",
      backgroundColor: 'white'
    })
  };

  menuTextVariants = {
    expanded: {
      opacity: 1
    },
    collapsed: {
      opacity: 0,
      transition: { duration: 0 }
    }
  };

  menuIconVariants = {
    expanded: {
      filter: "brightness(50%)"
    },
    collapsed: {
      filter: "brightness(100%)"
    }
  };

  contentVariants = {
    expanded: () => ({
      height: "auto",
      opacity: 1,
      marginBottom: "60px", 
    }),
    collapsed: () => ({
      height: 0,
      opacity: 0,
      marginBottom: 0, 
      transition: { duration: 0 }
      // top: "200px",
      // opacity: 0,
      // transition: { duration: 0 }
    })
  };


  sectionClick = (section) => {
    this.setState({currentSection: section});
    if (section === "prepare" && this.state.prepareOpen === false) {
      this.setState({prepareOpen: true});
    }
    if (section === "prepare" && this.state.prepareOpen === true) {
      this.setState({prepareOpen: false});
    }

    if (section === "define" && this.state.defineOpen === false) {
      this.setState({defineOpen: true});
    }
    if (section === "define" && this.state.defineOpen === true) {
      this.setState({defineOpen: false});
    }

    if (section === "ideate" && this.state.ideateOpen === false) {
      this.setState({ideateOpen: true});
    }
    if (section === "ideate" && this.state.ideateOpen === true) {
      this.setState({ideateOpen: false});
    }

    if (section === "design" && this.state.designOpen === false) {
      this.setState({designOpen: true});
    }
    if (section === "design" && this.state.designOpen === true) {
      this.setState({designOpen: false});
    }

    if (section === "test" && this.state.testOpen === false) {
      this.setState({testOpen: true});
    }
    if (section === "test" && this.state.testOpen === true) {
      this.setState({testOpen: false});
    }
  }

  boxClick = () => {
    if (this.state.currentSection === "prepare") {
      this.props.history.push('/tools/sprint-focus-cards');
    }
    if (this.state.currentSection === "define") {
      this.props.history.push('/tools/user-context-cards');
    }
    if (this.state.currentSection === "ideate") {
      this.props.history.push('/tools/design-principle-cards');
    }
    if (this.state.currentSection === "design") {
      this.props.history.push('/tools/inspiration-tool');
    }
    if (this.state.currentSection === "test") {
      this.props.history.push('/tools/test-framework');
    }
  }

  componentDidMount() {
    // console.log(this.props.currentTopNav);
  }

  render() {
    return (
      <div className="sprint-tools-section" id="sprintOverview">
      <Container>
        <Row className="focus-area-top">
          <Col className="text-center" lg={{ span: 6, offset: 3 }}>
            <div className="text-5 text-title-margin-bottom">Sprint Overview</div>
            <div className="text-2 text-title-margin-bottom">Use these tools in a Digital Confidence Design Sprint</div>
            <div className="">In anywhere from an hour to a week, your team can use the Digital Confidence Design Sprint methodology to infuse your process with empathy and rapidly build prototypes.</div>
          </Col>
        </Row>
        <Row>
          <Col lg={{ span: 4, offset: 2 }} className="menu-col">
            <motion.div 
              onClick={() => {this.sectionClick('prepare')}}
              animate={this.state.prepareOpen === true ? "expanded" : "collapsed"}
              variants={this.menuVariants}
              className="design-sprint-nav nav-prepare">
              <motion.img variants={this.menuIconVariants} className="tools-subpage-icon" src={require('../img/icons/purple-diagonal-line.png')} alt="tools"></motion.img>
              <div className="button-text-container">
                <div className="dom-text">Prepare</div>
                <motion.div div className="sub-text" variants={this.menuTextVariants}>
                Before the sprint begins, use the Sprint Focus Cards to narrow your design brief and prepare your team for success.
                </motion.div >
              </div>
            </motion.div>

            <motion.div className="box-content-container"                 
                        animate={this.state.prepareOpen === true ? "expanded" : "collapsed"}
                        variants={this.contentVariants}>
              <motion.img 
                className="box-content"
                src={require('../img/tools/sprint-tools/prepare.png')} 
                alt="prepare">
              </motion.img>
            </motion.div>



            <motion.div 
              onClick={() => {this.sectionClick('define')}}
              animate={this.state.defineOpen === true ? "expanded" : "collapsed"}
              variants={this.menuVariants}
              className="design-sprint-nav nav-define">
              <motion.img variants={this.menuIconVariants} className="tools-subpage-icon" src={require('../img/icons/orange-circle.png')} alt="tools"></motion.img>
              <div className="button-text-container">
                <div className="dom-text">Define</div>
                <motion.div className="sub-text" variants={this.menuTextVariants}>
                As you kick off the sprint, align on your brief and use the User Context Cards to build a persona.
                </motion.div>
              </div>
            </motion.div>

            <motion.div className="box-content-container"
              animate={this.state.defineOpen === true ? "expanded" : "collapsed"}
              variants={this.contentVariants}>
              <motion.img 
              className="box-content"
              src={require('../img/tools/sprint-tools/define.png')} 
              alt="define"></motion.img>
            </motion.div>




            <motion.div 
              onClick={() => {this.sectionClick('ideate')}}
              animate={this.state.ideateOpen === true ? "expanded" : "collapsed"}
              variants={this.menuVariants}
              className="design-sprint-nav nav-ideate">
              <motion.img variants={this.menuIconVariants} className="tools-subpage-icon" src={require('../img/icons/yellow-rectangle.png')} alt="tools"></motion.img>
              <div className="button-text-container">
                <div className="dom-text">Ideate</div>
                <motion.div className="sub-text" variants={this.menuTextVariants}>
                Next, articulate guiding principles for your designs and brainstorm new product concepts.
                </motion.div>
              </div>
            </motion.div>

            <motion.div className="box-content-container"
            animate={this.state.ideateOpen === true ? "expanded" : "collapsed"}
            variants={this.contentVariants}>
              <motion.img 
              className="box-content"
              src={require('../img/tools/sprint-tools/ideate.png')} 
              alt="ideate"></motion.img>
            </motion.div>





            <motion.div 
              onClick={() => {this.sectionClick('design')}}
              animate={this.state.designOpen === true ? "expanded" : "collapsed"}
              variants={this.menuVariants}
              className="design-sprint-nav nav-design">
              <motion.img variants={this.menuIconVariants} className="tools-subpage-icon" src={require('../img/icons/green-triangle.png')} alt="tools"></motion.img>
              <div className="button-text-container">
                <div className="dom-text">Design</div>
                <motion.div className="sub-text" variants={this.menuTextVariants}>
                As you begin designing, get inspired by products and provocations that will inform early-stage prototypes.
                </motion.div>
              </div>
            </motion.div>

            <motion.div className="box-content-container"
            animate={this.state.designOpen === true ? "expanded" : "collapsed"}
            variants={this.contentVariants}>
              <motion.img 
              className="box-content design"
              src={require('../img/tools/sprint-tools/design.png')} 
              alt="design"></motion.img>
            </motion.div>




            <motion.div 
              onClick={() => {this.sectionClick('test')}}
              animate={this.state.testOpen === true ? "expanded" : "collapsed"}
              variants={this.menuVariants}
              className="design-sprint-nav nav-test">
              <motion.img variants={this.menuIconVariants} className="tools-subpage-icon" src={require('../img/icons/black-diagonal-line.png')} alt="tools"></motion.img>
              <div className="button-text-container">
                <div className="dom-text">Test</div>
                <motion.div className="sub-text" variants={this.menuTextVariants}>
                As the sprint comes to a close, make an actionable plan to test and learn in the real world.
                </motion.div>
              </div>
            </motion.div>

            <motion.div className="box-content-container"
            animate={this.state.testOpen === true ? "expanded" : "collapsed"}
            variants={this.contentVariants}>
              <motion.img 
              className="box-content test"
              src={require('../img/tools/sprint-tools/test.png')} 
              alt="test"></motion.img>
            </motion.div>




          </Col>
          <Col lg={{ span: 5 }} className="tools-images relative">
              <img className="box-back" src={require('../img/tools/sprint-tools/box-back.png')} alt="box-back"></img>
              <motion.img 
                className="box-content"
                animate={this.state.currentSection === 'prepare' ? "expanded" : "collapsed"}
                variants={this.contentVariants}
                src={require('../img/tools/sprint-tools/prepare.png')} 
                alt="prepare"></motion.img>

              <motion.img 
                className="box-content"
                animate={this.state.currentSection === 'define' ? "expanded" : "collapsed"}
                variants={this.contentVariants}
                src={require('../img/tools/sprint-tools/define.png')} 
                alt="define"></motion.img>

              <motion.img 
                className="box-content"
                animate={this.state.currentSection === 'ideate' ? "expanded" : "collapsed"}
                variants={this.contentVariants}
                src={require('../img/tools/sprint-tools/ideate.png')} 
                alt="ideate"></motion.img>

              <motion.img 
                className="box-content design"
                animate={this.state.currentSection === 'design' ? "expanded" : "collapsed"}
                variants={this.contentVariants}
                src={require('../img/tools/sprint-tools/design.png')} 
                alt="design"></motion.img>

              <motion.img 
                className="box-content test"
                animate={this.state.currentSection === 'test' ? "expanded" : "collapsed"}
                variants={this.contentVariants}
                src={require('../img/tools/sprint-tools/test.png')} 
                alt="test"></motion.img>

              <img onClick={this.boxClick} className="box-front" src={require('../img/tools/sprint-tools/box-front.png')} alt="box-front"></img>

          </Col>
        </Row>
      </Container>
      </div>
    );
  }
}

export default withRouter(MobileSprintTools);
