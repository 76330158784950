import React, { Component } from 'react';
import './Tools.scss';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import TopNav from '../TopNav/TopNav'
import VideoModalDialog from '../ModalDialogVideo'
import MediaQuery from 'react-responsive'
import SprintTools from '../SprintTools/SprintTools';
import MobileSprintTools from '../SprintTools/MobileSprintTools';
import SprintLinks from '../Tools/SprintLinks';
import RunASprint from '../RunASprint/RunASprint';
import Footer from '../Footer/Footer'
import toolsTopBack from '../img/tools/tools-top-back.png';
import { withRouter } from "react-router-dom";

class Tools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false
    }
  }

  componentDidMount() {
    // console.log(this.props.currentTopNav);
  }

  openModel = () => {
    console.log('open me');
    this.setState({modalOpen: true});
  }

  render() {

    return (
      <div className="tools">
        <TopNav currentTopNav="tools" />
        <div className="page-top" style={{backgroundImage: `url(${toolsTopBack})`}}>
          <Container className="tools-top">
            <Row>
              <Col className="text-left relative" md={{ span: 5, offset: 1 }} xs={{ span: 10, offset: 1 }}>
                <img className="tools-top-squiggle" src={require('../img/tools/tools-top-squiggle.png')} alt="squiggle"></img>
                <div className="text-1">The Digital Confidence Design Tools</div>
                <VideoModalDialog />
              </Col>
            </Row>
          </Container>
        </div>

        <div className="light-background-section">
          <Container className="relative">
            <Row className="margin-bottom-me">
              <Col lg={{ span: 5, offset: 1 }} xs={{ span: 10, offset: 1 }}>
                <div className="text-2">Take advantage of a proven set of tools to build for the next wave of internet users</div>
              </Col>
            </Row>

            <Row className="margin-bottom-me-small">
              <Col lg={{ span: 3, offset: 1 }} xs={{ span: 10, offset: 1 }}>
                <div className="text-5 margin-bottom-mobile">
                  <div className="margin-bottom-me-small">
                    The tools – developed by IDEO, Google, and the Bill &amp; Melinda Gates Foundation – help teams design digital services for the next wave of internet users. 
                  </div>
                  <div className="text-5 regular">
                    Use them to see your app with fresh eyes, design new flows, develop innovative interaction patterns, or even brainstorm a brand-new product.
                  </div>
                </div>
              </Col>
              <Col lg={{ span: 4, offset: 1 }} xs={{ span: 11, offset: 1 }} className="mobile-no-right-padding">
                <img className="animated-gif" src={require('../animated-gifs/Flipkart.gif')} alt="flipcart"></img>
              </Col>
              <Col lg={{ span: 2, offset: 0 }} xs={{ span: 10, offset: 1 }} className="mobile-align-right">
                <img className="tools-logo flipkart-logo" src={require('../img/tools/flipkart-logo.png')} alt="Flipcart"></img>
                <div className="flipkart-text margin-bottom-mobile">
                  "The Sprint Canvas gave our team structure and kept us grounded in the needs of early smartphone users."
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="relative mobile-no-left-padding" lg={{ span: 4, offset: 1 }} xs={{ span: 10, offset: 0 }}>
                <img className="yellow-square" src={require('../img/tools/yellow-rect.png')} alt="squiggle"></img>
                <img className="animated-gif" src={require('../animated-gifs/Gpay.gif')} alt="gpay"></img>
                <div className="gpay-mobile-spacing">
                  <img className="tools-logo google-pay-logo" src={require('../img/tools/google-pay-logo.png')} alt="Flipcart"></img>
                  <div className="logo-caption margin-bottom-mobile">"The Inspiration Tool gave us tangible examples of how to design for new users."</div>
                </div>
              </Col>
              <Col lg={{span: 5, offset: 0}} className="relative" xs={{ span: 11, offset: 1 }} className="mobile-no-right-padding mobile-align-right">
                <img className="animated-gif airtel-gif" src={require('../animated-gifs/Airtel.gif')} alt="Airtel"></img>
                <img className="tools-middle-squiggle" src={require('../img/tools/tools-middle-squiggle.png')} alt="squiggle"></img>
                <div className="airtel-mobile-spacing">
                  <img className="tools-logo airtel-logo" src={require('../img/tools/airtel-payments-bank-logo.png')} alt="Airtel"></img>
                  <div className="logo-caption airtel">“In just a single day, we built research-ready prototypes for a whole new user flow.”</div>
                </div>
              </Col>
            </Row>

          </Container>
        </div>

        <SprintLinks />      


        <MediaQuery minDeviceWidth={992}>
          <SprintTools />
        </MediaQuery>
        <MediaQuery maxDeviceWidth={991}>
          <MobileSprintTools />
        </MediaQuery>

        <RunASprint />
        <Footer />
      </div>
    );
  }
}

export default withRouter(Tools);
