import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import buttonArrow from './img/button-arrow-white.png';
import YouTube from 'react-youtube';

function ModalDialog({openMe}) {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <div onClick={handleShow} style={{backgroundImage: `url(${buttonArrow})`}} className="green-button button1">Watch the video</div>
      <Modal show={show} onHide={handleClose} className="video-modal">

        <Modal.Body className="relative">
          <img onClick={handleClose} className="close-modal-button" src={require('./img/icons/close-white.png')} alt="Close"></img>
          
          <YouTube
            containerClassName="youtube-container"
            videoId='360_BkHMgeA'
            opts={{width: '100%', height: '100%'}}
          />

        </Modal.Body>

      </Modal>
    </div>
  );
}
export default ModalDialog;