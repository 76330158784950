import React from "react";
import { useRef} from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";

const ParallaxImage = () => {
  // const [elementTop, setElementTop] = useState(0);
  const ref = useRef(null);
  const { scrollY } = useViewportScroll();
  const y = useTransform(scrollY, [100, 105], [0, -1], {
    clamp: false
  });

  // useLayoutEffect(() => {
  //   const element = ref.current;
  //   setElementTop(element.offsetTop);
  // }, [ref]);

  return (
    <div ref={ref} className="image-container relative">
      <motion.img style={{ y }} className="parallax-image orange-circle" src={require('../img/orange-circle.png')} alt=""></motion.img>
    </div>
  );
};

export default ParallaxImage;
