import React, { Component } from 'react';
import './MoreResources.scss';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import animateScrollTo from 'animated-scroll-to';
import { Waypoint } from 'react-waypoint';


class MoreResources extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1
    }
  }

  componentDidMount() {
    // console.log(this.props.currentTopNav);
  }

  scrollHoriz = (page) => {
    if (page === 1) {
      this.setState({currentPage: 1});
      animateScrollTo([0, null], {minDuration: 500, elementToScroll: this.horizSlider});
    }
    if (page === 2) {
      this.setState({currentPage: 2});
      animateScrollTo(this.page2, {minDuration: 500, elementToScroll: this.horizSlider});
    }
    if (page === 3) {
      this.setState({currentPage: 3});
      animateScrollTo(this.page3, {minDuration: 500, elementToScroll: this.horizSlider});
    }
  }

  setPagerClass = (page) => {
    // more-resources-progress-bar on
    if (this.state.currentPage === page) {
      return "more-resources-progress-bar on";
    } else {
      return "more-resources-progress-bar";
    }
  }

  scrollListener = (page) => {
    if (page === 1) {
      this.setState({currentPage: 1});
    }
    if (page === 2) {
      this.setState({currentPage: 2});
    }
    if (page === 3) {
      this.setState({currentPage: 3});
    }
  }

  render() {
    return (
      <div className="more-resources light-background-section">
      <Container>
      <Row className="">
        <Col className="text-left" md={{ span: 3 }} lg={{ span: 2, offset: 1 }}>
          <div className="text-5 text-title-margin-bottom bold-text">More Resources</div>
          <div className="more-resources-progress-bar-container">
            <div onClick={() => {this.scrollHoriz(1)}} className={this.setPagerClass(1)}></div>
            <div onClick={() => {this.scrollHoriz(2)}} className={this.setPagerClass(2)}></div>
            <div onClick={() => {this.scrollHoriz(3)}} className={this.setPagerClass(3)}></div>

          </div>
        </Col>
        <Col className="text-left" md={{ span: 9 }}>

          <div ref={ n => {this.horizSlider = n }} className="resources-scroll-within">
            <div className="horizontal-container">

            <Waypoint horizontal={true} onEnter={() => {this.scrollListener(1)}}>
            <div className="more-resources-box-container">
                <img className="resources-photo" src={require('../img/more-resources/insights.png')} alt="Insights"></img>
                <div className="content-title">How Insights From User Research Help Us Build For The Next Billion</div>
                <div className="content-block text-6">Head of Research and Insights for Google’s Next Billion Users team shares how user experience research helps product teams stay connected to the pulse of the user.</div>
                <div className="learn-more">
                  <a className="resources-link" href="https://www.blog.google/technology/next-billion-users/how-insights-user-research-help-us-build-next-billion-users/" rel="noopener noreferrer" target="_blank">
                    Learn More
                    <img className="learn-more-arrow" src={require('../img/arrow-right-small-purple.png')} alt="Learn More"></img>
                  </a>
                </div>
              </div>
              </Waypoint>

              <div className="more-resources-box-container">
                <img className="resources-photo" src={require('../img/more-resources/women-money.png')} alt="Women in Money"></img>
                <div className="content-title">Women &amp; Money: A Path To Close The Gender Gap</div>
                <div className="content-block text-6">An IDEO.org program funded by the Bill and Melinda Gates Foundation to surface the complex realities that keep women excluded from financial services and build solutions that unlock new opportunities to include women.</div>
                <div className="learn-more">
                  <a className="resources-link" href="https://www.womenandmoney.design/" target="_blank" rel="noopener noreferrer">
                    Learn More
                    <img className="learn-more-arrow" src={require('../img/arrow-right-small-purple.png')} alt="Learn More"></img>
                  </a>
                </div>
              </div>

              <div className="more-resources-box-container">
                <img className="resources-photo" src={require('../img/more-resources/digital-skills.png')} alt="Digital Skills"></img>
                <div className="content-title">Digital Skills Observatory</div>
                <div className="content-block text-6">A research project designed to deepen the understanding of low-income first-time smartphone users with respect to adoption, app use, the web, and Digital Financial Services.</div>
                <div className="learn-more">
                  <a className="resources-link" href="https://d20x8vt12bnfa2.cloudfront.net/reports/Stepping+Into+Digital+Life+-+Digital+Skills+Observatory+Research+Report.pdf" rel="noopener noreferrer" target="_blank">
                    Learn More
                    <img className="learn-more-arrow" src={require('../img/arrow-right-small-purple.png')} alt="Learn More"></img>
                  </a>
                </div>
              </div>

              <div className="more-resources-box-container">
                <img className="resources-photo" src={require('../img/more-resources/assistive.png')} alt="Assistive"></img>
                <div className="content-title">Assistive UI Meets Bharat</div>
                <div className="content-block text-6">A first-of-its-kind assistant which can speak in users' native language, guide them live on app screen, and help them easily do complex tasks like making online payments.</div>
                <div className="learn-more">
                  <a className="resources-link" href="https://www.youtube.com/watch?v=JkQ4ID1nsq4&feature=emb_title" rel="noopener noreferrer" target="_blank">
                    Learn More
                    <img className="learn-more-arrow" src={require('../img/arrow-right-small-purple.png')} alt="Learn More"></img>
                  </a>
                </div>
              </div>



              <div ref={ n => {this.page2 = n }} className="more-resources-box-container">
                <img className="resources-photo" src={require('../img/more-resources/gender-equity.png')} alt="Gender Equity"></img>
                <div className="content-title">Toward Gender Equity Online</div>
                <div className="content-block text-6">In this report, Google has identified four areas that need to be addressed to move us toward a more representative internet: access, content and community, privacy, and safety.</div>
                <div className="learn-more">
                  <a className="resources-link" href="http://services.google.com/fh/files/misc/toward_gender_equity_online.pdf" rel="noopener noreferrer" target="_blank">
                    Learn More
                    <img className="learn-more-arrow" src={require('../img/arrow-right-small-purple.png')} alt="Learn More"></img>
                  </a>
                </div>
              </div>

              <div className="more-resources-box-container">
                <img className="resources-photo" src={require('../img/more-resources/low-literacy.png')} alt="Digital Skills"></img>
                <div className="content-title">Designing For Low-Literate Users</div>
                <div className="content-block text-6">Design principles and recommendations for computer-human interfaces that would allow a first-time, non-literate person, to immediately realize useful interaction with minimal or no external assistance.</div>
                <div className="learn-more">
                  <a className="resources-link" href="https://youtu.be/UVtzCDTNbMI" rel="noopener noreferrer" target="_blank">
                    Learn More
                    <img className="learn-more-arrow" src={require('../img/arrow-right-small-purple.png')} alt="Learn More"></img>
                  </a>
                </div>
              </div>

              <div className="more-resources-box-container">
                <img className="resources-photo" src={require('../img/more-resources/design-kit.png')} alt="Design Kit"></img>
                <div className="content-title">Design Kit: The Human-Centered Design Toolkit</div>
                <div className="content-block text-6">IDEO.org's platform to learn human-centered design, a creative approach to solving the world's most difficult problems.</div>
                <div className="learn-more">
                  <a className="resources-link" href="https://www.designkit.org/" rel="noopener noreferrer" target="_blank">
                    Learn More
                    <img className="learn-more-arrow" src={require('../img/arrow-right-small-purple.png')} alt="Learn More"></img>
                  </a>
                </div>
              </div>


              <Waypoint horizontal={true} onEnter={() => {this.scrollListener(2)}}>
              <div className="more-resources-box-container">
                <img className="resources-photo" src={require('../img/more-resources/inclusive.png')} alt="Inclusive"></img>
                <div className="content-title">Designing Inclusive Digital Solutions And Developing Digital Skills</div>
                <div className="content-block text-6">UNESCO guidelines to help today's technology pioneers build more inclusive digital solutions for  people with limited literacy skills and low digital skills.</div>
                <div className="learn-more">
                  <a className="resources-link" href="https://drive.google.com/file/d/1UqHnP0S1irXmMfOWoJNLgYsyYR4YYX4U/view" rel="noopener noreferrer" target="_blank">
                    Learn More
                    <img className="learn-more-arrow" src={require('../img/arrow-right-small-purple.png')} alt="Learn More"></img>
                  </a>
                </div>
              </div>
              </Waypoint>



              <div ref={ n => {this.page3 = n }} className="more-resources-box-container">
                <img className="resources-photo" src={require('../img/more-resources/great-apps.png')} alt="Great Apps"></img>
                <div className="content-title">Designing Great Apps For New Internet Users</div>
                <div className="content-block text-6">Learn best practices about designing products and experiences for constrained connectivity contexts from Google designers who think about this every day.</div>
                <div className="learn-more">
                  <a className="resources-link" href="https://youtu.be/Fo-1qrHeQXQ" rel="noopener noreferrer" target="_blank">
                    Learn More
                    <img className="learn-more-arrow" src={require('../img/arrow-right-small-purple.png')} alt="Learn More"></img>
                  </a>
                </div>
              </div>

              <div className="more-resources-box-container">
                <img className="resources-photo" src={require('../img/more-resources/flipkart.png')} alt="Flipkart"></img>
                <div className="content-title">How Flipkart Is Solving For Its Next 100 Million Customers</div>
                <div className="content-block text-6">Flipkart's User Research team talks about the various insights they gleaned over the course of several months - insights that will help them better serve the next hundred million Flipkart customers.</div>
                <div className="learn-more">
                  <a className="resources-link" href="https://soundcloud.com/studio34flipkart/how-flipkart-is-solving-for-its-next-100-million-customers" rel="noopener noreferrer" target="_blank">
                    Learn More
                    <img className="learn-more-arrow" src={require('../img/arrow-right-small-purple.png')} alt="Learn More"></img>
                  </a>
                </div>
              </div>

              <div className="more-resources-box-container">
                <img className="resources-photo" src={require('../img/more-resources/smartphones.png')} alt="Insights"></img>
                <div className="content-title">Smartphones And Mobile Money: Principles For UI/UX Design</div>
                <div className="content-block text-6">CGAP presents a set of UI/UX principles for mobile money smartphone interfaces delivered to the mass market in low-income countries.</div>
                <div className="learn-more">
                  <a className="resources-link" href="https://www.cgap.org/research/slide-deck/smartphones-and-mobile-money-principles-uiux-design-10" rel="noopener noreferrer"target="_blank">
                    Learn More
                    <img className="learn-more-arrow" src={require('../img/arrow-right-small-purple.png')} alt="Learn More"></img>
                  </a>
                </div>
              </div>

              <Waypoint horizontal={true} onEnter={() => {this.scrollListener(3)}}>
              <div className="more-resources-box-container">
                <img className="resources-photo" src={require('../img/more-resources/challenges.png')} alt="Challenges"></img>
                <div className="content-title">Challenges And Learnings Of Building For The Next Billion Users</div>
                <div className="content-block text-6">Building products and services that work for everyone regardless of where they live, what language they speak, which devices they use, or the network they're connected to presents exciting opportunities and challenges. </div>
                <div className="learn-more">
                  <a className="resources-link" href="https://youtu.be/6fBS8LOpIQc" rel="noopener noreferrer" target="_blank">
                    Learn More
                    <img className="learn-more-arrow" src={require('../img/arrow-right-small-purple.png')} alt="Learn More"></img>
                  </a>
                </div>
              </div>
              </Waypoint>




            </div>

          </div>


        </Col>
      </Row>
    </Container>
    </div>
    );
  }
}

export default MoreResources;
