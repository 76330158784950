import React, { Component } from 'react';
import './FocusAreas.scss';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import onboardingBack from '../img/focus-areas/onboarding.png';
import navigationBack from '../img/focus-areas/navigation.png';
import accountsBack from '../img/focus-areas/accounts.png';
import discoveryBack from '../img/focus-areas/discovery.png';
import assistanceBack from '../img/focus-areas/assistance.png';
import voiceBack from '../img/focus-areas/voice.png';
import visualsBack from '../img/focus-areas/visuals.png';
import languageBack from '../img/focus-areas/language.png';
import transactionsBack from '../img/focus-areas/transactions.png';
import dataBack from '../img/focus-areas/data.png';

class FocusAreas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentObstacle: "",
      currentHover: ""
    };
  }

  onMenuItemClick(whichItem) {
    if (this.state.currentObstacle === whichItem) {
      this.setState({currentObstacle: ""});
    } else {
      this.setState({currentObstacle: whichItem});
    }
  }

  onMenuMouseOver(whichItem) {
    this.setState({currentHover: whichItem});
  }

  onMenuMouseOut = () => {
    this.setState({currentHover: ""});
  }

  getContentAreaClass() {
    if (this.state.currentObstacle !== "") {
      return "focus-area-area open";
    } else {
      return "focus-area-area";
    }
  }

  getMenuBoxClass = (whichItem) => {
    if (whichItem === this.state.currentHover || whichItem === this.state.currentObstacle) {
      return "focus-area-nav-box";
    } else if (this.state.currentHover === "" && this.state.currentObstacle === "") {
      return "focus-area-nav-box"
    } else {
      return "focus-area-nav-box faded"
    }
  }

  render() {
    return (
    <div className="focus-areas-container">
    <Container>
      <Row className="focus-area-top">
        <Col className="text-center" md={{ span: 6, offset: 3 }}>
          <div className="text-5 text-title-margin-bottom">Focus Areas</div>
          <div className="text-2">Explore common obstacles facing new users</div>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <div onClick={() => this.onMenuItemClick('onboarding')}
               onMouseOver={() => this.onMenuMouseOver('onboarding')}
               onMouseOut={this.onMenuMouseOut}
               className={this.getMenuBoxClass('onboarding')}>
            <img className="focus-area-nav-image" src={require('../img/icons/onboarding.png')} alt="onboarding"></img>
            Onboarding
          </div>
          <div onClick={() => this.onMenuItemClick('navigation')} 
               onMouseOver={() => this.onMenuMouseOver('navigation')}
               onMouseOut={this.onMenuMouseOut}
               className={this.getMenuBoxClass('navigation')}>
            <img className="focus-area-nav-image" src={require('../img/icons/navigation.png')} alt="navigation"></img>
            Navigation
          </div>
          <div onClick={() => this.onMenuItemClick('accounts')} 
               onMouseOver={() => this.onMenuMouseOver('accounts')}
               onMouseOut={this.onMenuMouseOut}
               className={this.getMenuBoxClass('accounts')}>
            <img className="focus-area-nav-image" src={require('../img/icons/accounts.png')} alt="accounts"></img>
            Accounts
          </div>
          <div onClick={() => this.onMenuItemClick('discovery')} 
               onMouseOver={() => this.onMenuMouseOver('discovery')} 
               onMouseOut={this.onMenuMouseOut}
               className={this.getMenuBoxClass('discovery')}>
            <img className="focus-area-nav-image" src={require('../img/icons/discovery.png')} alt="discovery"></img>
            Discovery
          </div>
          <div onClick={() => this.onMenuItemClick('assistance')} 
               onMouseOver={() => this.onMenuMouseOver('assistance')} 
               onMouseOut={this.onMenuMouseOut}
               className={this.getMenuBoxClass('assistance')}>
            <img className="focus-area-nav-image" src={require('../img/icons/assistance.png')} alt="assistance"></img>
            Assistance
          </div>

          <div className={this.getContentAreaClass()}>

            {this.state.currentObstacle === "onboarding" &&
            <Container className="focus-area-container" style={{backgroundImage: `url(${onboardingBack})`}}>
              <Row>
                <Col className="text-left" md={{span: 5, offset: 1}}>
                  <div className="text-1-5 focus-area-title">Onboarding</div>
                  <div className="flex-row text-5-5">
                    <div className="flex-column">
                      <div className="bold-text text-title-margin-bottom">An app’s purpose isn’t always readily apparent.</div> 
                      Apps can cause uncertainty and frustration when their purpose and value are unclear, especially when they are pre-installed or installed by others. Most users rely on friends, family, or phone sales agents for information about which apps to use and why. They may miss crucial context about your app's value proposition or capabilities.
                    </div>
                    <div className="flex-column text-title-margin-bottom">
                    <div className="bold-text text-title-margin-bottom">Users will abandon the signup flow if it becomes overwhelming.</div> 
                    Installing new apps and setting up accounts is a moment of drop-off for many, and can be especially overwhelming for users who are less digitally confident. You risk losing your users if the onboarding flow is long, doesn’t communicate its value early on, or is otherwise confusing.
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            }


            {this.state.currentObstacle === "navigation" &&
            <Container className="focus-area-container navigation-focus-area" style={{backgroundImage: `url(${navigationBack})`}}>
              <Row>
                <Col className="text-left" md={{span: 5, offset: 1}}>
                  <div className="text-1-5 focus-area-title">Navigation</div>
                  <div className="flex-row text-5-5">
                    <div className="flex-column">
                      <div className="bold-text text-title-margin-bottom">Navigating through hierarchical structures is challenging. </div> 
                      Studies have shown that navigating tree structures is more challenging for people with low literacy. They prefer linear navigation (forward and back) and find it disorienting and counterintuitive to follow complex nesting, deep hierarchy, and scrolling interfaces as they move deeper into flows.
                    </div>
                    <div className="flex-column text-title-margin-bottom">
                    <div className="bold-text text-title-margin-bottom">Knowing when and where to tap, swipe, and long press is unclear.</div> 
                    The process of learning what’s clickable and what’s not can lead to users down incorrect pathways. This is amplified by inconsistent interfaces across different apps and operating systems.
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            }

            {this.state.currentObstacle === "accounts" &&
            <Container className="focus-area-container" style={{backgroundImage: `url(${accountsBack})`}}>
              <Row>
                <Col className="text-left" md={{span: 5, offset: 1}}>
                  <div className="text-1-5 focus-area-title">Accounts</div>
                  <div className="flex-row text-5-5">
                    <div className="flex-column">
                      <div className="bold-text text-title-margin-bottom">The mental model of an “account” can be confusing.</div> 
                      Without an understanding of digital accounts and identities and the organizations behind them, it’s not obvious why you would need a Google account to set up an Android phone, or why you would then need a different account for a service like Facebook. 
                    </div>
                    <div className="flex-column text-title-margin-bottom">
                    <div className="bold-text text-title-margin-bottom">Creating an account (and remembering the credentials) is difficult.</div> 
                    The amount of information required to set up an account can feel overwhelming. Plus, remembering alphanumeric passwords is difficult for lower literacy users, especially if these credentials were created by someone else.
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            }

            {this.state.currentObstacle === "discovery" &&
            <Container className="focus-area-container discovery-focus-area" style={{backgroundImage: `url(${discoveryBack})`}}>
              <Row>
                <Col className="text-left" md={{span: 5, offset: 1}}>
                  <div className="text-1-5 focus-area-title">Discovery</div>
                  <div className="flex-row text-5-5">
                    <div className="flex-column">
                      <div className="bold-text text-title-margin-bottom">The contents and scale of the internet aren’t immediately apparent. </div> 
                      Without a mental model of what’s available on the internet, the next wave of internet users are often unaware of what their smartphone can offer. For instance, if someone has always found a job through friends and family, it can be confusing that they can achieve the same goal online.  
                    </div>
                    <div className="flex-column text-title-margin-bottom">
                    <div className="bold-text text-title-margin-bottom">Users don’t feel confident enough to experiment on their own within an app.</div> 
                    Many users fear doing something “wrong,” which can limit them from discovering new features within an app.
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            }

            {this.state.currentObstacle === "assistance" &&
            <Container className="focus-area-container assistance-focus-area" style={{backgroundImage: `url(${assistanceBack})`}}>
              <Row>
                <Col className="text-left" md={{span: 5, offset: 1}}>
                  <div className="text-1-5 focus-area-title">Assistance</div>
                  <div className="flex-row text-5-5">
                    <div className="flex-column">
                      <div className="bold-text text-title-margin-bottom">It’s difficult to know who (or what) to trust.</div> 
                      Your users have turned to friends, family, and neighbors for help in the real world their entire lives. It can be difficult to know who to trust for honest advice and help when they’re facing issues in the digital world, especially when its delivery is less personal.
                    </div>
                    <div className="flex-column text-title-margin-bottom">
                    <div className="bold-text text-title-margin-bottom">Asking for help isn’t always easy.</div> 
                    In some contexts, people may feel embarrassed, awkward, or ashamed to ask for help, especially when their question relates to something “simple” like using their own smartphone. 
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            }

            {this.state.currentObstacle === "voice" &&
            <Container className="focus-area-container" style={{backgroundImage: `url(${voiceBack})`}}>
              <Row>
                <Col className="text-left" md={{span: 5, offset: 1}}>
                  <div className="text-1-5 focus-area-title">Voice</div>
                  <div className="flex-row text-5-5">
                    <div className="flex-column">
                      <div className="bold-text text-title-margin-bottom">Today’s voice interactions can feel error-prone and alienating.</div> 
                      Voice interpretation and transcription is extremely error-prone. Misinterpretations happen to everyone, especially if the experience is not tailored to native languages, variations in accents, or is taking place in a loud area. However, users often blame themselves. Just a few frustrating experiences can lead people to abandon voice interactions altogether. 
                    </div>
                    <div className="flex-column text-title-margin-bottom">
                    <div className="bold-text text-title-margin-bottom">Voice interfaces don’t operate consistently.</div> 
                    Once a user has developed an understanding of how to use a particular voice feature, they expect it to always operate that way. However, inconsistencies across products and task  flows make it hard for users to know what to do and gain confidence.  
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            }

            {this.state.currentObstacle === "visuals" &&
            <Container className="focus-area-container visuals-focus-area" style={{backgroundImage: `url(${visualsBack})`}}>
              <Row>
                <Col className="text-left" md={{span: 5, offset: 1}}>
                  <div className="text-1-5 focus-area-title">Visuals</div>
                  <div className="flex-row text-5-5">
                    <div className="flex-column">
                      <div className="bold-text text-title-margin-bottom">An unfamiliar visual aesthetic affects a user’s understanding and willingness to interact.</div> 
                      Visuals created without regard for the local semiotic landscape often don’t make sense to the next wave of internet users. A “shopping cart” icon, for instance, may not resonate with someone who isn’t familiar with supermarkets. 
                    </div>
                    <div className="flex-column text-title-margin-bottom">
                    <div className="bold-text text-title-margin-bottom">Imagery and iconography that are either too abstract or too detailed don’t resonate.</div> 
                    Abstract illustrations and iconography can come across as too vague. Alternatively, photo-realistic visuals provide extraneous details that can distract. Visuals that show real-life actions and are semi-abstract often communicate better than static objects or standalone symbols. 
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            }

            {this.state.currentObstacle === "language" &&
            <Container className="focus-area-container" style={{backgroundImage: `url(${languageBack})`}}>
              <Row>
                <Col className="text-left" md={{span: 5, offset: 1}}>
                  <div className="text-1-5 focus-area-title">Language</div>
                  <div className="flex-row text-5-5">
                    <div className="flex-column">
                      <div className="bold-text text-title-margin-bottom">Content is often not available in a user’s preferred language.</div> 
                      More than 50% of web content is currently written in English. Hindi, the #4 language in terms of global speakers, isn’t even in the top 30 languages for web content. Even when content is translated into a user’s preferred language, it’s often poor quality (incorrect, too formal,  not relatable). 
                    </div>
                    <div className="flex-column text-title-margin-bottom">
                    <div className="bold-text text-title-margin-bottom">Switching between multiple languages is challenging.</div> 
                    The next wave of internet users often live in areas that support a mix of regional, vernacular, and official languages. While they’re accustomed to switching languages in everyday life, it’s less intuitive (and sometimes impossible) to do so on their phones or within a product experience.
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            }

            {this.state.currentObstacle === "transactions" &&
            <Container className="focus-area-container" style={{backgroundImage: `url(${transactionsBack})`}}>
              <Row>
                <Col className="text-left" md={{span: 5, offset: 1}}>
                  <div className="text-1-5 focus-area-title">Transactions</div>
                  <div className="flex-row text-5-5">
                    <div className="flex-column">
                      <div className="bold-text text-title-margin-bottom">Digital currency feels less trustworthy than cash.</div> 
                      For those accustomed to a cash-based economy, the inability to tangibly see or feel money may stoke apprehension. The lack of physical artifacts like receipts and ATM cards can contribute to a sense that their money isn’t secure or raise doubts about whether their transactions are successful.  
                    </div>
                    <div className="flex-column text-title-margin-bottom">
                    <div className="bold-text text-title-margin-bottom">Flexible and varied payment methods are needed.</div> 
                    Users should be able to choose the payment method that fits their financial circumstances. For instance, cash-on-delivery is popular in many markets, as it doesn’t require a credit or debit card, or even a bank account. Paying in installments over time is also popular for those whose income may be low or unpredictable.
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            }

            {this.state.currentObstacle === "data" &&
            <Container className="focus-area-container" style={{backgroundImage: `url(${dataBack})`}}>
              <Row>
                <Col className="text-left" md={{span: 5, offset: 1}}>
                  <div className="text-1-5 focus-area-title">Data</div>
                  <div className="flex-row text-5-5">
                    <div className="flex-column">
                      <div className="bold-text text-title-margin-bottom">Data consumption can feel opaque and uncontrollable.</div> 
                      It’s often unclear how much data apps and actions will consume. This is especially frustrating for the 95% of smartphone users in emerging markets who use prepaid or pay-as-you-go data. 
                    </div>
                    <div className="flex-column text-title-margin-bottom">
                    <div className="bold-text text-title-margin-bottom">Product development often assumes uninterrupted connectivity.</div> 
                    To conserve data, people often switch it on and off throughout the day. This can result in error states and poor user experiences.
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            }
          </div>

          <div onClick={() => this.onMenuItemClick('voice')} 
               onMouseOver={() => this.onMenuMouseOver('voice')} 
               onMouseOut={this.onMenuMouseOut}
               className={this.getMenuBoxClass('voice')}>
            <img className="focus-area-nav-image" src={require('../img/icons/voice.png')} alt="voice"></img>
            Voice          
          </div>
          <div onClick={() => this.onMenuItemClick('visuals')} 
               onMouseOver={() => this.onMenuMouseOver('visuals')} 
               onMouseOut={this.onMenuMouseOut}
               className={this.getMenuBoxClass('visuals')}>
            <img className="focus-area-nav-image" src={require('../img/icons/visuals.png')} alt="visuals"></img>
            Visuals
          </div>
          <div onClick={() => this.onMenuItemClick('language')} 
               onMouseOver={() => this.onMenuMouseOver('language')} 
               onMouseOut={this.onMenuMouseOut}
               className={this.getMenuBoxClass('language')}>
            <img className="focus-area-nav-image" src={require('../img/icons/language.png')} alt="language"></img>
            Language
          </div>
          <div onClick={() => this.onMenuItemClick('transactions')} 
               onMouseOver={() => this.onMenuMouseOver('transactions')} 
               onMouseOut={this.onMenuMouseOut}
               className={this.getMenuBoxClass('transactions')}>
            <img className="focus-area-nav-image" src={require('../img/icons/transactions.png')} alt="transactions"></img>
            Transactions
          </div>
          <div onClick={() => this.onMenuItemClick('data')} 
               onMouseOver={() => this.onMenuMouseOver('data')} 
               onMouseOut={this.onMenuMouseOut}
               className={this.getMenuBoxClass('data')}>
            <img className="focus-area-nav-image" src={require('../img/icons/data.png')} alt="data"></img>
            Data
          </div>

        </Col>
      </Row>
    </Container>
    </div>


    );
  }
}

export default FocusAreas;
