import React, { Component } from 'react';
import './RunASprint.scss';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import emailjs from 'emailjs-com';
import animateScrollTo from 'animated-scroll-to';


class RunASprint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formOpen: false,
      formSubmitted: false,
      fullName: "",
      email: "",
      organization: "",
      industry: "",
      whyExcited: "",
      location: ""
    }
  }

  openForm = () => {
    if (this.state.formOpen) {
      this.setState({formOpen: false});
    } else {
      this.setState({formOpen: true});
    }

  }

  formContainerClass = () => {
    if (this.state.formOpen === true) {
      return "digital-sprint-form-container-container open"
    } else {
      return "digital-sprint-form-container-container"
    }
  }

  getButtonClass = () => {
    if (this.state.formOpen) {
      return "button1 margin-bottom-me-small"
    } else {
      return "button1 margin-bottom-me-small"
    }
  }

  getButtonText = () => {
    if (this.state.formOpen) {
      return "Close"
    } else {
      return "Let's run a sprint"
    }
  }

  thanksClassName = () => {
    if (this.state.formSubmitted) {
      return "thanks";
    } else {
      return "thanks hide";
    }
  }

  submitButtonClass = () => {
    if (this.state.formSubmitted) {
      return "button1 sprint-form-submit hide";
    } else {
      return "button1 sprint-form-submit";
    }
  }

  componentDidMount = () => {
    this.scrollHMWs();
  }

  scrollHMWs = () => {
    animateScrollTo([13000, null], {minDuration: 600000, elementToScroll: this.horizontalSlider}).then(hasScrolledToPosition => {
      // scroll animation is finished
      // "hasScrolledToPosition" indicates if page/element
      // was scrolled to a desired position
      // or if animation got interrupted
      if (hasScrolledToPosition) {
        // console.log('finished');
        // page is scrolled to a desired position
      } else {
        // console.log('no here');
        // scroll animation was interrupted by user
        // or by another call of "animateScrollTo"
      }
    });    
  }

  submitForm = (e) => {
    e.preventDefault();
    emailjs.send('eklein@ideo.com', 'template_GQRT5yKe_clone', {
      fullName: this.state.fullName,
      email: this.state.email,
      organization: this.state.organization,
      industry: this.state.industry,
      whyExcited: this.state.whyExcited,
      location: this.state.location
    }, 'user_8e6EAauzjBMDFmHrfquT7')
    .then((result) => {
        console.log(result.text);
        this.setState({formSubmitted: true});
    }, (error) => {
        console.log(error.text);
    });
  }

  updateFullName(evt) {
    this.setState({
      fullName: evt.target.value
    });
  }

  updateEmail(evt) {
    this.setState({
      email: evt.target.value
    });
  }

  updateOrganization(evt) {
    this.setState({
      organization: evt.target.value
    });
  }

  updateIndustry(evt) {
    this.setState({
      industry: evt.target.value
    });
  }

  updateWhyExcited(evt) {
    this.setState({
      whyExcited: evt.target.value
    });
  }

  updateLocation(evt) {
    this.setState({
      location: evt.target.value
    });
  }



  render() {
    return (
      <div className="normal-section hide-overflow">
      <Container>
        <Row className="">
          <Col md={{ span: 4, offset: 1 }} xs={{ span: 10, offset: 1 }}>
            <div className="text-2">Interested in running a sprint together?</div>
          </Col>
        </Row>

        <Row>
          <Col md={{ span: 12 }} xs={{ span: 11, offset: 1 }} className="relative">
            <div className="horizontal-slider-cover"></div>
            <div ref={ n => {this.horizontalSlider = n }} className="horizontal-slider-container">
              <div className="horizontal-slider" id="horizontal-slider">
                <div className="sprint-hmw-container">
                  <strong>How might we</strong> onboard the next wave of internet users onto our app?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> help daily wage earners find short-term jobs?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> make sending money as easy as sending a message?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> build flexible UI that evolves over time?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> let users create multiple profiles on a shared device?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> equip rural female entrepreneurs with digital business tools?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> enable a first-time smartphone owner to digitally repay a loan?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> onboard the next wave of internet users onto our app?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> help daily wage earners find short-term jobs?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> make sending money as easy as sending a message?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> build flexible UI that evolves over time?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> let users create multiple profiles on a shared device?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> equip rural female entrepreneurs with digital business tools?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> enable a first-time smartphone owner to digitally repay a loan?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> onboard the next wave of internet users onto our app?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> help daily wage earners find short-term jobs?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> make sending money as easy as sending a message?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> build flexible UI that evolves over time?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> let users create multiple profiles on a shared device?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> equip rural female entrepreneurs with digital business tools?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> enable a first-time smartphone owner to digitally repay a loan?
                </div>
                <div className="sprint-hmw-container">
                  <strong>How might we</strong> onboard the next wave of internet users onto our app?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> help daily wage earners find short-term jobs?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> make sending money as easy as sending a message?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> build flexible UI that evolves over time?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> let users create multiple profiles on a shared device?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> equip rural female entrepreneurs with digital business tools?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> enable a first-time smartphone owner to digitally repay a loan?
                </div>
                <div className="sprint-hmw-container">
                  <strong>How might we</strong> onboard the next wave of internet users onto our app?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> help daily wage earners find short-term jobs?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> make sending money as easy as sending a message?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> build flexible UI that evolves over time?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> let users create multiple profiles on a shared device?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> equip rural female entrepreneurs with digital business tools?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> enable a first-time smartphone owner to digitally repay a loan?
                </div>
                <div className="sprint-hmw-container">
                  <strong>How might we</strong> onboard the next wave of internet users onto our app?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> help daily wage earners find short-term jobs?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> make sending money as easy as sending a message?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> build flexible UI that evolves over time?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> let users create multiple profiles on a shared device?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> equip rural female entrepreneurs with digital business tools?
                </div>

                <div className="sprint-hmw-container">
                  <strong>How might we</strong> enable a first-time smartphone owner to digitally repay a loan?
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="run-a-sprint-button-container" md={{ span: 11, offset: 1 }} xs={{ span: 8, offset: 1 }}>
            <div onClick={this.openForm} className={this.getButtonClass()}>{this.getButtonText()}</div>
          </Col>
        </Row>
      </Container>

      <div className={this.formContainerClass()}>
        <Form onSubmit={(e) => {this.submitForm(e)}}>
        <Container className="relative digital-sprint-form-container">
          <Row className="margin-bottom-me">
            <Col md={{ span: 5, offset: 1 }}>
              <div className="text-2 margin-bottom-me-small">Run a Digital Confidence Design Sprint</div>
              <div className="text-5">Please share a few details to see if you’re a great fit for our Digital Confidence Design Sprint program.</div>
            </Col>
          </Row>
          <Row className="margin-bottom-me">
            <Col md={{ span: 5, offset: 1 }}>
              <div className="text-5 bold-text text-title-margin-bottom-small">Full Name</div>
              <Form.Control value={this.state.fullName} onChange={evt => this.updateFullName(evt)} className="margin-bottom-me-small" type="text" placeholder="Rita Brown" />
              <div className="text-5 bold-text text-title-margin-bottom-small">Email Address</div>
              <Form.Control value={this.state.email} onChange={evt => this.updateEmail(evt)} className="margin-bottom-me-small" type="email" placeholder="rita@newcorp.com"></Form.Control>

              <div className="text-5 bold-text text-title-margin-bottom-small">Organization</div>
              <Form.Control value={this.state.organization} onChange={evt => this.updateOrganization(evt)} className="margin-bottom-me-small" type="text" placeholder="Newcorp" />

              <div className="text-5 bold-text text-title-margin-bottom-small">Industry</div>
              <Form.Control value={this.state.industry} onChange={evt => this.updateIndustry(evt)} className="margin-bottom-me-small" type="text" placeholder="E-commerce" />
              <div className="privacy-text text-6">Please read our <a href="https://www.ideo.com/privacy" rel="noopener noreferrer" target="_blank">Privacy Policy</a> and <a href="https://www.ideo.com/privacy#privacy-rights">CA User Rights</a> to see how we use your personal information.</div>
            </Col>
            <Col md={{ span: 5 }}>
              <div className="text-5 bold-text text-title-margin-bottom-small">Why are you excited to run a sprint?</div>
              <Form.Control value={this.state.whyExcited} onChange={evt => this.updateWhyExcited(evt)} className="margin-bottom-me-small" as="textarea" placeholder="My team would love to run a digital confidence design sprint because…" rows="4" />
              
              <div className="text-5 bold-text text-title-margin-bottom-small">Where would you like to run a sprint?</div>
              <Form.Control value={this.state.location} onChange={evt => this.updateLocation(evt)} className="margin-bottom-me-small" type="text" placeholder="City, Country" />
              <button type="submit" className={this.submitButtonClass()}>Submit Response</button>

              <div className={this.thanksClassName()}>Thanks! We'll be in touch.</div>
            </Col>
          </Row>
        </Container>
        </Form>
      </div>
    </div>
    );
  }  
}


export default RunASprint;
